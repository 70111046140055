import React, { useState } from 'react'

// components
import {
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
} from '@coreui/react-pro'
import AppFirstForm from './AppFirstForm'
import AppSecondForm from './AppSecondForm'

const AppForm = ({
  editPage,
  values,
  handleChange,
  errors,
  couponTypeOptions,
  openCouponPeriod,
  setOpenCouponPeriod,
  handleApplyCouponTo,
  handleRemoveImage,
  handleFileInput,
  handleFileOnDrop,
  handleStartDateRange,
  handleEndDateRange,
  handleMultiSelect,
  setOpenFilePreview,
  couponDetails
}) => {

  return (
    <CAccordion activeItemKey={1}>
      <CAccordionItem itemKey={1}>
        <CAccordionHeader>
          <b>Step 1: </b> &nbsp; Basic Coupon Information
        </CAccordionHeader>
        <CAccordionBody>
          <AppFirstForm
            editPage={editPage}
            values={values}
            handleChange={handleChange}
            errors={errors}
            couponTypeOptions={couponTypeOptions}
            openCouponPeriod={openCouponPeriod}
            setOpenCouponPeriod={setOpenCouponPeriod}
            handleRemoveImage={handleRemoveImage}
            handleChangeImage={handleFileInput}
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            handleStartDateRange={handleStartDateRange}
            handleEndDateRange={handleEndDateRange}
            setOpenFilePreview={setOpenFilePreview}
          />
        </CAccordionBody>
      </CAccordionItem>
      <CAccordionItem itemKey={2}>
        <CAccordionHeader>
          <b>Step 2: </b> &nbsp; Coupon Eligibility
        </CAccordionHeader>
        <CAccordionBody>
          <AppSecondForm
            editPage={editPage}
            values={values}
            handleChange={handleChange}
            handleMultiSelect={handleMultiSelect}
            errors={errors}
            handleApplyCouponTo={handleApplyCouponTo}
          />
        </CAccordionBody>
      </CAccordionItem>
    </CAccordion>
  )
}

export default AppForm
