import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppDetails from 'src/components/details/my-page-details/AppDetails'

// helpers
import Axios from 'src/helpers/axios'

const MyPage = () => {
  const navigate = useNavigate()

  const [userDetails, setUserDetails] = useState('')

  const handleCardHeaderButton = () => {
    navigate(`/profile/edit/${user.id}`)
    window.location.reload();
  }

  useEffect(() => {
    fetchProfileUser()
  }, []);

  const fetchProfileUser = () => {
    Axios({
      method: "get",
      url: `/api/sso/v1/users/profile`,
    })
      .then(({ data }) => {
        setUserDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={<h4>My Page</h4>}
      bodyContent={
        <AppDetails
          userDetails={userDetails}
        />
      }
    />
  )
}

export default MyPage
