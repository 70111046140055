import React, { useState, useEffect } from 'react'

// components
import AppCard from 'src/components/AppCard'
import AppTable from 'src/components/AppTable'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// helpers
import { columnCustomerList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'
import { getFullName } from 'src/helpers/text-format'

const CustomerList = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [customerList, setCustomerList] = useState([]);
  const [customerListCount, setCustomerListCount] = useState(0);
  const [customerListPerPage, setCustomerListPerPage] = useState(10);
  const [activeCustomerListPage, setActiveCustomerListPage] = useState(1);
  const [searchCustomerListFilter, setSearchCustomerListFilter] = useState("");
  const [columnCustomerListSorter, setColumnCustomerListSorter] = useState({ column: "updatedAt", state: "desc" });

  useEffect(() => {
    let queries = {
      search: searchCustomerListFilter,
      // limit: customerListPerPage,
      offset: customerListPerPage * activeCustomerListPage - customerListPerPage,
      sort:
        (columnCustomerListSorter &&
          `${columnCustomerListSorter.column}%${columnCustomerListSorter.state}`) ||
        "",
    };
    queries = formUrlQuery(queries);
    fetchAllCustomers(queries);
  }, [
    searchCustomerListFilter,
    customerListPerPage,
    activeCustomerListPage,
    columnCustomerListSorter,
  ]);

  const fetchAllCustomers = (queries) => {
    Axios({
      method: "get",
      url: `/api/sso/v1/users/regular?${queries}`,
    })
      .then(({ data }) => {
        const tempCustomerList = data.map((d) => ({
          ...d,
          customer_id: "ID12345",
          phone_number: "+628123456",
          full_name: getFullName(d?.profile)
        }));

        setCustomerList(tempCustomerList);
        if (activeCustomerListPage === 1) {
          setCustomerListCount(tempCustomerList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/customers/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          deleteConfirmation
          itemName='customer'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Customer List</h4>}
        bodyContent={
          <AppTable
            customerListPage
            selectable
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnCustomerList}
            tableContent={customerList}
            contentPerPage={customerListPerPage}
            activeContentPage={activeCustomerListPage}
            contentCount={customerListCount}
            searchContentFilter={searchCustomerListFilter}
            setActiveContentPage={setActiveCustomerListPage}
            setSearchContentFilter={setSearchCustomerListFilter}
            setContentPerPage={setCustomerListPerPage}
            setColumnContentSorter={setColumnCustomerListSorter}
          />
        }
      />
    </>
  )
}

export default CustomerList
