import React from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CFormLabel,
  CFormText,
  CRow
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import { getFullName } from 'src/helpers/text-format'

const AppFirstDetails = ({
  customerDetails
}) => {
  return (
    <CRow>
      <CCol lg='3'>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Customer ID"
            subtitle={customerDetails?.customer_id || '-'}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Customer Name"
            subtitle={getFullName(customerDetails?.profile)}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Gender"
            subtitle={customerDetails?.profile?.gender || '-'}
          />
        </CRow>
      </CCol>
      <CCol lg='3'>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Registered On"
            subtitle={moment(customerDetails?.createdAt).format('DD/MM/YYYY HH:mm')}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Email Address"
            subtitle={customerDetails?.email}
          />
        </CRow>
        <CRow>
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title="Phone Number"
            subtitle={customerDetails?.profile?.phone_number || '-'}
          />
        </CRow>
      </CCol>
    </CRow>
  )
}

export default AppFirstDetails
