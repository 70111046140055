import React, { useState } from 'react'

// components
import {
  CRow,
  CBadge
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

// helpers
import { productStatus } from 'src/helpers/status-badge'

const AppDetails = ({
  productDetails,
}) => {

  return (
    <>
      <CRow className='mb-4'>
        <AppTextColumnDetails
          colSize="3"
          title="Voucher ID"
          subtitle={productDetails?.sku}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Voucher Name"
          subtitle={productDetails?.name}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Game Name"
          subtitle={productDetails?.sub_category?.name}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Game Type"
          subtitle={productDetails?.sub_category?.category?.name}
        />
      </CRow>
      <CRow className='mb-4'>
        <AppTextColumnDetails
          colSize="3"
          title="Price"
          subtitle={`IDR ${productDetails?.price}`}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Stock"
          subtitle={productDetails?.qty}
        />
        <AppTextColumnDetails
          colSize="3"
          title="Status"
          subtitle={
            <CBadge
              className='table-badge-status-content'
              color={productStatus(productDetails?.status)}
              shape="rounded-pill"
            >
              {productDetails?.status?.toString().toUpperCase()}
            </CBadge>
          }
        />
        <AppTextColumnDetails
          colSize="3"
          title="Voucher Type"
          subtitle={productDetails?.is_product_voucher ? "Product Voucher" : "Redeemable Voucher"}
        />
      </CRow>
      <CRow className='mb-4'>
        <AppTextColumnDetails
          colSize="6"
          title="Voucher Description"
          subtitle={productDetails?.description}
        />
        <AppImageDetail
          colSize="6"
          title="Voucher Banner Image"
          productImages={productDetails?.images && productDetails?.images[0] && productDetails?.images[0]?.image_url}
        />
      </CRow>
    </>
  )
}

export default AppDetails
