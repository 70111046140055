import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

// components
import {
  CRow,
  CCol,
  CImage,
  CBadge,
  CFormText,
  CFormLabel
} from '@coreui/react-pro'
import AppCard from 'src/components/AppCard'

// icons 
import PurchaseBasketIcon from 'src/assets/icons/PurchaseBasketIcon.png'
import ExampleGame1 from 'src/assets/images/ExampleGame1.png'

// helpers
import { orderStatus } from 'src/helpers/status-badge'

const CustomerPurchaseHistory = ({
  customerDetails,
  customerPurchaseHistory
}) => {
  const navigate = useNavigate()

  return (
    <>
      {customerPurchaseHistory?.length > 0 ? (
        <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 2 }}>
          {customerPurchaseHistory?.map((purchase, index) => {
            return (
              <CCol xs onClick={() => navigate(`/purchases/details/${purchase?.id}`)} style={{ cursor: "pointer" }}>
                <AppCard
                  className="mt-2"
                  headerTitle={
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <b>{`PURCHASE ID: ${purchase?.transaction_id}`}</b> &nbsp; {moment(purchase?.transaction_date).format('DD/MM/YYYY HH:mm')}
                      </div>
                      <CBadge className='table-badge-status-content' color={orderStatus(purchase?.payment_status)} shape="rounded-pill">
                        {purchase?.payment_status?.toString().toUpperCase()}
                      </CBadge>
                    </div>
                  }
                  bodyContent={
                    <CRow >
                      <CCol lg='2' >
                        <CImage fluid thumbnail rounded src={purchase?.cart_items[0]?.product?.images[0]?.image_url} width={100} />
                      </CCol>
                      <CCol lg='4'>
                        <CFormLabel>{purchase?.cart_items[0]?.product?.name}</CFormLabel>
                        <CFormText><small>{`+${purchase?.cart_items?.length - 1} More Items`}</small></CFormText>
                      </CCol>
                      <CCol lg='4'>
                        <CFormLabel>Total Amount</CFormLabel>
                        <CFormText>{purchase?.transaction_amount}</CFormText>
                      </CCol>
                    </CRow>
                  }
                  key={index}
                />
              </CCol>
            )
          })}
        </CRow>
      ) : (
        <div className='my-5 text-center'>
          <CImage className='mb-3' fluid src={PurchaseBasketIcon} />
          <h5><b>No Purchase History Yet</b></h5>
        </div>
      )}
    </>
  )
}

export default CustomerPurchaseHistory
