export const styleContainerVars1 = {
  maxWidth: "1500px",
  "--cui-gutter-x": "0rem"
}

export const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '1rem',
  padding: '1rem',
  textAlign: 'center',
  borderWidth: 2,
  borderRadius: '1rem',
  borderColor: '#9E9E9E',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

export const focusedStyle = {
  borderColor: '#000'
};

export const acceptStyle = {
  borderColor: '#000'
};

export const textUploadDropZoneStyle1 = {
  color: '#9E9E9E',
  fontSize: '14px'
};

export const textUploadDropZoneStyle2 = {
  color: '#9E9E9E',
  fontSize: '20px'
};

export const styleAddImageVariant = {
  outline: "2px dashed #9E9E9E",
  borderRadius: ".5rem",
  padding: "3rem",
  cursor: "pointer"
}