import React, { Suspense, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
// import Lottie from "lottie-react"

// components
import { CContainer, CSpinner, CButton, CRow, CCol } from '@coreui/react-pro'
// import EdupiaLoading from "../assets/lottie/edupia-loading.json"
// import NoInternetConnection from "../assets/lottie/no-internet-connection.json"

// styling
import { styleContainerVars1 } from './custom-styles/containerStyle'

// routes config
import routes from '../routes'

const AppContent = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  // const [isOnline, setIsOnline] = useState(() => {
  //   if (navigator.onLine) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });

  // useEffect(() => {
  //   window.ononline = (e) => {
  //     setIsOnline(true);
  //     dispatch({
  //       type: 'SET_TOAST_MESSAGE',
  //       toastMessage: {
  //         isError: true,
  //         isShown: true,
  //         color: 'success',
  //         text: "Connected to network."
  //       }
  //     })
  //   };
  //   window.onoffline = (e) => {
  //     setIsOnline(false);
  //     dispatch({
  //       type: 'SET_TOAST_MESSAGE',
  //       toastMessage: {
  //         isError: true,
  //         isShown: true,
  //         color: 'danger',
  //         text: "No internet connection."
  //       }
  //     })
  //   };
  // }, [isOnline]);

  // const LoadingSpinner = () => {
  //   const style = {
  //     height: 300
  //   };

  //   return (
  //     <div
  //       style={{
  //         marginTop: "10em",
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         textAlign: "center",
  //       }}
  //     >
  //       <Lottie 
  //         animationData={EdupiaLoading} 
  //         loop={true} 
  //         style={style}
  //       />
  //       <h4
  //         style={{
  //           color: "#345AE0",
  //         }}
  //       >
  //         Loading Data...
  //       </h4>
  //     </div>
  //   )
  // }

  // const NoInternetConnectionState = () => {
  //   const style = {
  //     height: 300
  //   };

  //   return (
  //     <div
  //       style={{
  //         marginTop: "10em",
  //         display: "flex",
  //         flexDirection: "column",
  //         justifyContent: "center",
  //         textAlign: "center",
  //       }}
  //     >
  //       <Lottie 
  //         animationData={NoInternetConnection} 
  //         loop={true} 
  //         style={style}
  //       />
  //       <h4
  //         style={{
  //           color: "#345AE0",
  //         }}
  //       >
  //         No Internet Connection.
  //       </h4>
  //       <h4
  //         style={{
  //           color: "#345AE0",
  //         }}
  //       >
  //         Please check your internet settings...
  //       </h4>
  //       <CRow>
  //         <CCol lg="12">
  //           <CButton 
  //             color='primary'
  //             size='lg'
  //             onClick={() => window.location.reload()}
  //           >
  //             Refresh Page
  //           </CButton>
  //         </CCol>
  //       </CRow>
  //     </div>
  //   )
  // }

  // if (!isOnline) {
  //   return (
  //     <NoInternetConnectionState />
  //   ) 
  // } 

  return (
    <CContainer style={styleContainerVars1}>
      <Suspense fallback={<CSpinner color='primary' />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          <Route path='/' element={<Navigate to='dashboard' replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  )
}

export default AppContent
