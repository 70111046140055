import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CMultiSelect
} from '@coreui/react-pro'

const FormCouponValue = ({
  id,
  name,
  colSize,
  className,
  couponType,
  value,
  handleTextInput,
  feedbackInvalid,
  invalid,
}) => {

  return (
    <CCol lg={colSize} className={className}>
      {couponType === "fixed-amount" && (
        <>
          <CFormLabel>
            Coupon Value
          </CFormLabel>
          <CInputGroup>
            <CInputGroupText>IDR</CInputGroupText>
            <CFormInput
              id={id}
              name={name}
              type="text"
              value={value}
              onChange={(e) => handleTextInput(e)}
              feedbackInvalid={feedbackInvalid}
              invalid={invalid}
            />
          </CInputGroup>
        </>
      )}
      {couponType === "percentage" && (
        <>
          <CFormLabel>
            Coupon Value
          </CFormLabel>
          <CInputGroup>
            <CFormInput
              id={id}
              name={name}
              type="text"
              value={value}
              onChange={(e) => handleTextInput(e)}
              feedbackInvalid={feedbackInvalid}
              invalid={invalid}
            />
            <CInputGroupText>%</CInputGroupText>
          </CInputGroup>
        </>
      )}
    </CCol>
  )
}

export default FormCouponValue
