import React from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormText,
  CImage
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'
import AppCard from 'src/components/AppCard'

// icons 
import WishlistIcon from 'src/assets/icons/WishlistIcon.png'
import Shirt1 from 'src/assets/images/ExampleGame1.png'

const AppSecondDetails = ({
  couponDetails
}) => {
  const selectedProduct = [{
    id: 1,
    sku: 'ABX38204',
    product_name: 'Polo Shirt Signature',
    category: 'Clothing'
  }, {
    id: 2,
    sku: 'ABX38205',
    product_name: 'Polo Shirt Signature',
    category: 'Clothing'
  }, {
    id: 3,
    sku: 'ABX38207',
    product_name: 'Polo Shirt Signature',
    category: 'Clothing'
  }, {
    id: 4,
    sku: 'ABX38208',
    product_name: 'Polo Shirt Signature',
    category: 'Clothing'
  }]

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Apply Coupon To"
          subtitle={'Selected Product'}
        // subtitle={couponDetails?.apply_coupon_to}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Uses Per Coupon"
          subtitle={'Unlimited'}
        // subtitle={couponDetails?.uses_per_coupon}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Uses Per Customer"
          subtitle={'1'}
        // subtitle={couponDetails?.uses_per_customer}
        />
      </CRow>
      <CRow>
        <CCol lg='12'>
          <CFormLabel>
            Product Eligibility
          </CFormLabel>
          {selectedProduct.length > 0 && (
            <CRow className='customer-details-tab-container' xs={{ cols: 1, gutter: 2 }} md={{ cols: 3 }}>
              {selectedProduct.map((item, index) => {
                return (
                  <CCol xs onClick={() => navigate(`/product/details/${item.id}`)}>
                    <AppCard
                      headerTitle={<h5>{item.sku}</h5>}
                      bodyContent={
                        <CRow>
                          <CCol lg='3'>
                            <CImage fluid thumbnail rounded src={Shirt1} width={100} />
                          </CCol>
                          <CCol lg='6'>
                            <CFormLabel>{item.product_name}</CFormLabel>
                            <CFormText>{item.category}</CFormText>
                          </CCol>
                        </CRow>
                      }
                      key={index}
                    />
                  </CCol>
                )
              })}
            </CRow>
          )}
        </CCol>
      </CRow>
    </>
  )
}

export default AppSecondDetails
