import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from "react-redux"
import moment from 'moment'
import { io } from "socket.io-client"

// components
import AppCard from 'src/components/AppCard'
import AppChatDetails from 'src/components/details/chat-details/AppChatDetails'
import Axios from 'src/helpers/axios'

const socket = io("http://localhost:3123/");

const filters = [
  {
    label: "Date",
    type: "date",
    items: [
      {
        label: "Today",
        name: "today",
      },
      {
        label: "This Week",
        name: "week",
      },
      {
        label: "This Month",
        name: "month",
      },
      {
        label: "This Year",
        name: "year",
      },
      {
        label: "All Time",
        name: "all",
      },
    ],
  },
]

const Chat = () => {
  const user = useSelector((state) => state.user)

  const endOfMessageRef = useRef(null)
  const [chatRooms, setChatRooms] = useState([])
  const [activeChatRoomId, setActiveChatRoomId] = useState(0)
  const [newMessage, setNewMessage] = useState("")
  const [activeTabKey, setActiveTabKey] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [queries, setQueries] = useState('')
  const [activeFilter, setActiveFilter] = useState({
    date: "all"
  })

  const activeChatRoom = chatRooms?.find(chat => chat?.id === activeChatRoomId)

  const sortedActiveRooms = chatRooms
    .filter(room => room.ChatMessages.length > 0)
    .sort((a, b) => moment(b?.ChatMessages?.[b?.ChatMessages?.length - 1]?.createdAt) - moment(a?.ChatMessages?.[a?.ChatMessages?.length - 1]?.createdAt))

  const sortedEmptyRooms = chatRooms
    .filter(room => room.ChatMessages.length === 0)
    .sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt))

  const sortedChatRooms = [...sortedActiveRooms, ...sortedEmptyRooms].filter(room => room.is_active === (activeTabKey === 1) ? true : false)

  const scrollToBottom = useCallback(() => {
    if (endOfMessageRef?.current) {
      setTimeout(() => endOfMessageRef.current?.scrollIntoView({ behavior: "instant" }), 10)
    }
  }, [endOfMessageRef?.current])

  const createChatMessage = async (chat_room_id, sender_id, text) => {
    try {
      const data = {
        chat_room_id,
        sender_id,
        text,
      }
      const response = await Axios.post('/api/support-chat/v1/chat-messages', data)
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }

  const getChatRooms = async (queries) => {
    try {
      const q = queries ? `?${queries}` : ""
      const response = await Axios.get(`/api/support-chat/v1/chat-rooms${q}`)
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }

  const readChatRoom = async (chat_room_id) => {
    try {
      const response = await Axios.post(`/api/support-chat/v1/chat-rooms/read-chat-room/${chat_room_id}`)
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }

  const endChat = async (chat_room_id) => {
    try {
      const response = await Axios.patch(`/api/support-chat/v1/chat-rooms/end/${chat_room_id}`)
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }

  const fetchChatRooms = useCallback(async () => {
    const _chatRooms = await getChatRooms(queries);
    setChatRooms(_chatRooms);
    scrollToBottom();
  }, [queries, scrollToBottom])

  const handleOpenRoom = async (chatRoom) => {
    setActiveChatRoomId(chatRoom.id)
    await readChatRoom(chatRoom.id)
    fetchChatRooms();
  }

  const handleChange = (e) => {
    setNewMessage(e.target.value)
  }

  const handleSubmit = async (e) => {
    if (newMessage) {
      await createChatMessage(activeChatRoomId, user?.id, newMessage)
      socket.emit("new-message", activeChatRoomId, user?.role?.id, newMessage)
      fetchChatRooms();
      setNewMessage("")
    }
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e)
    }
  }

  const handleEndChat = async () => {
    await endChat(activeChatRoomId)
    setActiveChatRoomId(0)
    setShowModal(false)
    fetchChatRooms()
  }

  const handleSelectFilter = (e, type) => {
    setActiveFilter(prev => ({
      ...prev,
      [type]: e.target.name
    }))
  }

  const handleFilter = () => {
    const _activeFilter = Object.entries(activeFilter)?.filter(([key, value]) => value)
    const _queries = _activeFilter.map(([key, value]) => `${key}=${value}`).join("&")
    if (_queries) {
      setQueries(_queries)
      setShowFilterModal(false)
    }
  }

  useEffect(() => {
    fetchChatRooms();
  }, [fetchChatRooms])

  useEffect(() => {
    socket.on("new-message", () => fetchChatRooms())
    return () => socket.off("new-message")
  }, [fetchChatRooms])

  useEffect(() => {
    socket.on("other-new-message", () => fetchChatRooms())
    return () => socket.off("other-new-message")
  }, [fetchChatRooms])

  useEffect(() => {
    socket.on("bot-new-message", () => fetchChatRooms())
    return () => socket.off("bot-new-message")
  }, [fetchChatRooms])

  useEffect(() => {
    socket.on("connect", async () => {
      const _chatRooms = await getChatRooms(queries);
      const chat_room_ids = _chatRooms.map(room => room.id)
      const data = {
        roomId: chat_room_ids,
        userId: user?.id,
      }
      socket.emit("join-room", data)
      setChatRooms(_chatRooms);
      scrollToBottom();
    })
    return () => socket.off("connect")
  }, [user?.id, queries, scrollToBottom])

  useEffect(() => {
    socket.on("update-chat-room", async () => {
      const _chatRooms = await getChatRooms(queries);
      setChatRooms(_chatRooms);
      scrollToBottom();
      const chat_room_ids = _chatRooms.map(room => room.id)
      const data = {
        roomId: chat_room_ids,
        userId: user?.id,
      }
      socket.emit("join-room", data)
    })
    return () => socket.off("update-chat-room")
  }, [user?.id, queries, scrollToBottom])

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle="Chats"
      bodyContent={
        <AppChatDetails
          user={user}
          activeChatRoom={activeChatRoom}
          activeChatRoomId={activeChatRoomId}
          sortedChatRooms={sortedChatRooms}
          newMessage={newMessage}
          endOfMessageRef={endOfMessageRef}
          handleChange={handleChange}
          handleOnKeyDown={handleOnKeyDown}
          handleSubmit={handleSubmit}
          handleEndChat={handleEndChat}
          handleOpenRoom={handleOpenRoom}
          handleFilter={handleFilter}
          handleSelectFilter={handleSelectFilter}
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          showFilterModal={showFilterModal}
          setShowFilterModal={setShowFilterModal}
          showModal={showModal}
          setShowModal={setShowModal}
          filters={filters}
          activeFilter={activeFilter}
        />
      }
    />
  )
}

export default Chat
