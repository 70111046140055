import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormText,
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

const AppDetails = ({
  subCategoryDetails
}) => {

  return (
    <>
      <CRow>
        <CCol>
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Game Name"
            subtitle={subCategoryDetails?.name}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Game Description"
            subtitle={subCategoryDetails?.description}
          />
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Game Type"
            subtitle={subCategoryDetails?.category?.name}
          />
        </CCol>
        <CCol>
          <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Voucher Type"
            subtitle={subCategoryDetails?.is_subcategory_voucher ? "Product Voucher" : "Redeemable Voucher"}
          // subtitle={subCategoryDetails?.description}
          />
          {/* <AppTextColumnDetails
            className="mb-3"
            colSize="12"
            title="Selected Server"
            subtitle={'Server ABC, Server DEF, Server FGH'}
          subtitle={subCategoryDetails?.description}
          /> */}
          <AppImageDetail
            colSize="12"
            className="mb-3"
            title="Game Banner Image"
            productImages={subCategoryDetails?.image_url}
          />
        </CCol>
      </CRow>
    </>
  )
}

export default AppDetails
