
import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CImage
} from '@coreui/react-pro'

import ExampleGame1 from '../../../assets/images/ExampleGame1.png'

const AppImageDetail = ({
  colSize,
  className,
  title,
  productImages,
}) => {
  return (
    <CCol lg={colSize} className={className}>
      <CCol lg='12'>
        <CFormLabel>
          {title}
        </CFormLabel>
      </CCol>
      {productImages && (
        <CCol lg='12'>
          <CImage
            fluid
            rounded
            thumbnail
            className='mt-2'
            src={productImages}
            width={300}
          />
        </CCol>
      )}
    </CCol>
  )
}

export default AppImageDetail