import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormText,
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import { getFullName } from 'src/helpers/text-format'

const AppDetails = ({
  userDetails,
}) => {

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="3"
          title="Full Name"
          subtitle={getFullName(userDetails?.profile)}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="3"
          title="Username"
          subtitle={userDetails?.username}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          className="mb-3"
          colSize="3"
          title="Email Address"
          subtitle={userDetails?.email}
        />
      </CRow>
    </>
  )
}

export default AppDetails
