import React from 'react'

// Dashboard
import Dashboard from './views/dashboard/Dashboard'

// Coupons
import CouponsList from './views/coupons/CouponsList'
import AddCoupon from './views/coupons/AddCoupon'
import EditCoupon from './views/coupons/EditCoupon'
import CouponDetails from './views/coupons/CouponDetails'

// Buttons
import MyPage from './views/my-page/MyPage'

// Purchases
import PurchaseList from './views/purchases/PurchaseList'
import PurchaseDetails from './views/purchases/PurchaseDetails'

// Products
import ProductList from './views/products/ProductList'
import AddProduct from './views/products/AddProduct'
import EditProduct from './views/products/EditProduct'
import ProductDetails from './views/products/ProductDetails'

// Products - Category
import CategoryList from './views/products/category/CategoryList'
import AddCategory from './views/products/category/AddCategory'
import EditCategory from './views/products/category/EditCategory'
import CategoryDetails from './views/products/category/CategoryDetails'

// Products - Sub Category
import SubCategoryList from './views/products/sub-category/SubCategoryList'
import AddSubCategory from './views/products/sub-category/AddSubCategory'
import EditSubCategory from './views/products/sub-category/EditSubCategory'
import SubCategoryDetails from './views/products/sub-category/SubCategoryDetails'

// Chats
import Chat from './views/chats/Chat'

// Customers
import CustomerList from './views/customers/CustomerList'
import CustomerDetails from './views/customers/CustomerDetails'

const routes = [
  { path: '/', name: 'Home', element: Dashboard, exact: true, },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard, exact: true },
  { path: '/coupons', name: 'Coupons', element: CouponsList, exact: true },
  { path: '/coupons/list', name: 'Coupon List', element: CouponsList },
  { path: '/coupons/add', name: 'Add Coupon', element: AddCoupon },
  { path: '/coupons/edit/:id', name: 'Edit Coupon', element: EditCoupon },
  { path: '/coupons/details/:id', name: 'Coupon Details', element: CouponDetails },
  { path: '/my-page', name: 'My Page', element: MyPage, exact: true },
  { path: '/purchases', name: 'Purchases', element: PurchaseList, exact: true },
  { path: '/purchases/list', name: 'Purchase List', element: PurchaseList },
  { path: '/purchases/details/:id', name: 'Purchase Details', element: PurchaseDetails },
  { path: '/products', name: 'Voucher', element: ProductList, exact: true, },
  { path: '/products/list', name: 'Voucher List', element: ProductList },
  { path: '/products/add', name: 'Add Voucher', element: AddProduct },
  { path: '/products/edit/:id', name: 'Edit Voucher', element: EditProduct },
  { path: '/products/details/:id', name: 'Product Voucher', element: ProductDetails },
  { path: '/products/category/list', name: 'Game Type List', element: CategoryList },
  { path: '/products/category/add', name: 'Add Game Type', element: AddCategory },
  { path: '/products/category/edit/:id', name: 'Edit Game Type', element: EditCategory },
  { path: '/products/category/details/:id', name: 'Game Type Details', element: CategoryDetails },
  { path: '/products/sub-category/list', name: 'Game List', element: SubCategoryList },
  { path: '/products/sub-category/add', name: 'Add Game', element: AddSubCategory },
  { path: '/products/sub-category/edit/:id', name: 'Edit Game', element: EditSubCategory },
  { path: '/products/sub-category/details/:id', name: 'Game Details', element: SubCategoryDetails },
  { path: '/chat', name: 'Chat', element: Chat, exact: true },
  { path: '/customers', name: 'Customers', element: CustomerList, exact: true },
  { path: '/customers/list', name: 'Customer List', element: CustomerList },
  { path: '/customers/details/:id', name: 'Customer Details', element: CustomerDetails },
]

export default routes
