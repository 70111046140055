
import React from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

const CustomerPurchaseDetails = ({
  purchaseDetails
}) => {

  return (
    <>
      <CRow>
        <CCol lg='6' className="mb-3">
          <AppTextColumnDetails
            colSize="12"
            className="mb-3"
            title={`INVOICE ${purchaseDetails?.invoice_id}`}
          />
          {purchaseDetails?.cart_items?.length > 0 && (
            <CTable borderless>
              <CTableBody>
                {purchaseDetails?.cart_items?.map((purchase, index) => {
                  return (
                    <CTableRow key={index}>
                      <CTableDataCell>{`${purchase?.qty}x ${purchase?.product?.name}`}</CTableDataCell>
                      <CTableDataCell>{`IDR ${purchase?.product?.price}`}</CTableDataCell>
                    </CTableRow>
                  )
                })}
                <CTableRow>
                  <CTableDataCell>
                    <b>TOTAL</b>
                    <p style={{ fontStyle: 'italic', color: '#9E9E9E', fontSize: '14px' }}>include tax</p>
                  </CTableDataCell>
                  <CTableDataCell><b>{`IDR ${purchaseDetails?.transaction_amount}`}</b></CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell style={{ fontStyle: 'italic', fontSize: '14px' }}>{`* ${purchaseDetails?.cart_items?.length} items in this transaction`}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          )}
        </CCol>
        <CCol lg='6' className="mb-3">
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Selected Server"
              subtitle={""}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Phone Number"
              subtitle={purchaseDetails?.user?.phone_number}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Email Address"
              subtitle={purchaseDetails?.customer_email?.email}
            />
          </CRow>
          <CRow>
            <AppTextColumnDetails
              colSize="12"
              className="mb-3"
              title="Payment Type"
              subtitle={purchaseDetails?.payment_channel?.name}
            />
          </CRow>
        </CCol>
      </CRow>
    </>
  )
}

export default CustomerPurchaseDetails