import React, { useState, useEffect } from 'react'

// components
import { CButton, CImage } from '@coreui/react-pro'
import AppCard from 'src/components/AppCard.js'
import AppTable from 'src/components/AppTable.js'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'

// helpers
import { columnSubCategoryList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'

const SubCategoryList = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryListCount, setSubCategoryListCount] = useState(0);
  const [subCategoryListPerPage, setSubCategoryListPerPage] = useState(10);
  const [activeSubCategoryListPage, setActiveSubCategoryListPage] = useState(1);
  const [searchSubCategoryListFilter, setSearchSubCategoryListFilter] = useState("");
  const [columnSubCategoryListSorter, setColumnSubCategoryListSorter] = useState({ column: "updatedAt", state: "desc" });

  useEffect(() => {
    let queries = {
      search: searchSubCategoryListFilter,
      // limit: subCategoryListPerPage,
      offset: subCategoryListPerPage * activeSubCategoryListPage - subCategoryListPerPage,
      sort:
        (columnSubCategoryListSorter &&
          `${columnSubCategoryListSorter.column}%${columnSubCategoryListSorter.state}`) ||
        "",
    };
    queries = formUrlQuery(queries);
    fetchAllSubCategories(queries);
  }, [
    searchSubCategoryListFilter,
    subCategoryListPerPage,
    activeSubCategoryListPage,
    columnSubCategoryListSorter,
  ]);

  const fetchAllSubCategories = (queries) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/sub/all?${queries}`,
    })
      .then(({ data }) => {
        const tempSubCategoryList = data.map((d) => ({
          ...d,
          category_name: d?.category?.name || 'Not Available'
        }));

        setSubCategoryList(tempSubCategoryList);
        if (activeSubCategoryListPage === 1) {
          setSubCategoryListCount(tempSubCategoryList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/categories/sub/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          deleteConfirmation
          itemName='game'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
        <CButton href='/products/sub-category/add' style={styleButtonVars2}>
          <CImage fluid src={AddMoreIconWhite} className='me-2' />
          Add Game
        </CButton>
      </div>
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Game List</h4>}
        bodyContent={
          <AppTable
            subCategoryListPage
            selectable
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnSubCategoryList}
            tableContent={subCategoryList}
            contentPerPage={subCategoryListPerPage}
            activeContentPage={activeSubCategoryListPage}
            contentCount={subCategoryListCount}
            searchContentFilter={searchSubCategoryListFilter}
            setActiveContentPage={setActiveSubCategoryListPage}
            setSearchContentFilter={setSearchSubCategoryListFilter}
            setContentPerPage={setSubCategoryListPerPage}
            columnContentSorter={columnSubCategoryListSorter}
            setColumnContentSorter={setColumnSubCategoryListSorter}
          />
        }
      />
    </>
  )
}

export default SubCategoryList
