import React, { useState } from 'react'

// components
import {
  CRow,
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'

const AppForm = ({
  editPage,
  values,
  errors,
  handleChange,
  handleRemoveImage,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  setOpenFilePreview
}) => {

  return (
    <>
      <CRow className="mb-4">
        <FormTextInput
          id='category_name'
          name='category_name'
          colSize='6'
          inputLabel='Game Type Name'
          placeholder='Enter game type name...'
          value={values.category_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.category_name}
          invalid={errors.category_name}
        />
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='category_desc'
          name='category_desc'
          colSize='6'
          inputLabel='Game Type Description'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Enter game type description...'
          rows='10'
          value={values.category_desc}
          handleTextAreaInput={handleChange}
        />
        {values.category_images.length > 0 ? (
          <FormImageThumbnail
            editPage={editPage}
            name='category_images'
            inputLabel='Game Type Banner Image'
            colSize='6'
            productImages={values.category_images}
            handleChangeImage={handleChangeImage}
            handleRemoveImage={handleRemoveImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='category_images'
            colSize='6'
            inputLabel='Game Type Banner Image'
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            multiple={false}
          />
        )}
      </CRow>
    </>
  )
}

export default AppForm
