import React, { useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-category/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'

const AddCategory = () => {
  const navigate = useNavigate()

  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      category_name: '',
      category_desc: '',
      category_images: []
    },
    validationSchema: Yup.object().shape({
      category_name: Yup.string().required("Please enter category name.")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  const handleInputValue = (values) => {
    const formData = new FormData()

    formData.append('name', values.category_name)
    if (values.category_images.length > 0) {
      formData.append('file', values.category_images[0])
    }
    if (values.category_desc !== '') {
      formData.append('description', values.category_desc)
    }

    return formData
  }

  const handlePost = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: '/api/ecommerce/v1/categories/create',
      data: payload
    })
      .then(({ data }) => {
        navigate('/products/category/list')
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleSave = (values) => {
    const inputPayload = handleInputValue(values)
    handlePost(inputPayload)
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      category_images: values.category_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        category_images: attachments
      }), false)
    }
  }

  const handleImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        category_images: attachments
      }), false)
    }
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Add Game Type</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            handleRemoveImage={handleRemoveImage}
            handleFileInput={handleImageAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/products/category/list')}
            onSave={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default AddCategory
