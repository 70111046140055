import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppCardHeader from 'src/components/card/AppCardHeader'
import AppFirstDetails from 'src/components/details/customer-details/AppFirstDetails'
import AppSecondDetails from 'src/components/details/customer-details/AppSecondDetails'

// helpers
import Axios from 'src/helpers/axios'

const CustomerDetails = () => {
  const params = useParams()
  const { id } = params

  const [customerDetails, setCustomerDetails] = useState('')
  const [customerPurchaseHistory, setCustomerPurchaseHistory] = useState([])

  useEffect(() => {
    fetchCustomerDetails()
    fetchCustomerPurchaseHistory()
  }, [id]);

  const fetchCustomerDetails = () => {
    Axios({
      method: "get",
      url: `/api/sso/v1/users/id/${id}`,
    })
      .then(({ data }) => {
        setCustomerDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchCustomerPurchaseHistory = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/transactions/user/id/${id}`,
    })
      .then(({ data }) => {
        setCustomerPurchaseHistory(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h4>Customer Details</h4>}
          // headerButton="VERIFIED"
          disabled
        />
      }
      bodyContent={
        <AppFirstDetails
          customerDetails={customerDetails}
        />
      }
      lowerBodyContent={
        <AppSecondDetails
          customerDetails={customerDetails}
          customerPurchaseHistory={customerPurchaseHistory}
        />
      }
    />
  )
}

export default CustomerDetails
