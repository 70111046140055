import React from 'react'

// components
import AppCard from 'src/components/AppCard.js'
import AppCardHeader from 'src/components/card/AppCardHeader'
import CustomerPurchaseDetails from './CustomerPurchaseDetails'

const AppSecondDetails = ({
  handleCardHeaderButton,
  purchaseDetails
}) => {

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h5>Purchase Details</h5>}
          headerDownloadButton="Download Receipt"
          downloadLink={purchaseDetails?.invoice?.file_url}
          downloadName={`INVOICE ${purchaseDetails?.transaction_id}`}
        />
      }
      bodyContent={
        <CustomerPurchaseDetails
          purchaseDetails={purchaseDetails}
        />
      }
    />
  )
}

export default AppSecondDetails
