import * as icon from "@coreui/icons";

export const singleOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && (item.name || item.option_name)
      options.push({
        label: name,
        ...id ? { value: id } : { value: name }
      })
    })
    options = [
      {
        label: 'Please select...',
        value: ''
      },
      ...options,
    ]
  }
  return options
}

export const singleCountriesOptionsSelect = (data) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && item.name
      const phone_code = item && item.phone_code
      const country_code = item && item.country_code
      options.push({
        label: name,
        value: JSON.stringify({
          id: id,
          name: name,
          icon: icon[`cif${country_code}`],
          phone_code: phone_code,
        })
      })
    })
    options = [
      {
        label: 'Please select country...',
        value: {}
      },
      ...options,
    ]
  }
  return options
};