import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import Axios from "../helpers/axios";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");

  useEffect(() => {
    if (token) {
      Axios({
        method: "get",
        url: "/api/sso/v1/users/profile",
      })
        .then(({ data }) => {
          if (data) {
            dispatch({ type: "SET_USER", user: data });
          } else {
            navigate("/login");
          }
        })
        .catch((err) => {
          console.error(err);
          navigate("/login");
        });
    } else {
      navigate("/login");
    }
  }, [])

  if (token) {
    return children
  } else {
    navigate("/login");
  }

  // const afterRegister = localStorage.getItem("after_register");

  // useEffect(() => {
  //   localStorage.setItem("credentials", "");
  //   if (token) {
  //     if (afterRegister && Boolean(afterRegister)) {
  //       localStorage.setItem("after_register", "");
  //       localStorage.setItem("email_storage", "");
  //       Cookies.remove("ut");
  //       dispatch({ type: "SET_USER", user: {} });
  //       navigate("/login");
  //     } else {
  //       Axios({
  //         method: "get",
  //         url: "/users",
  //       })
  //         .then(({ data }) => {
  //           if (data) {
  //             dispatch({ type: "SET_USER", user: data });
  //           } else {
  //             navigate("/login");
  //           }
  //         })
  //         .catch((err) => {
  //           navigate("/login");
  //         });
  //     }
  //   }
  // }, []);

  // if (!token) {
  //   return <Navigate to="login" replace />;
  // } else {
  //   if (Boolean(afterRegister) === false && user) {
  //     return children;
  //   }
  // }
};

export default LoginGuard;
