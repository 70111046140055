import React, { useState } from 'react'

// components
import {
  CCol,
  CFormLabel,
  CMultiSelect
} from '@coreui/react-pro'

const FormMultiSelectInput = ({
  id,
  name,
  inputLabel,
  placeholder,
  colSize,
  className,
  value,
  selectionOptions,
  handleMultiSelect,
  feedbackInvalid,
  invalid,
}) => {


  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CMultiSelect
        id={id}
        name={name}
        placeholder={placeholder}
        // options={value ? removeDuplicates(value.concat(selectionOptions)) : selectionOptions}
        options={selectionOptions}
        value={value}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
        onChange={(value) => handleMultiSelect(value)}
        selectionType="tags"
        virtualScroller
        visibleItems={5}
      />
    </CCol>
  )
}

export default FormMultiSelectInput
