import React, { useState } from 'react'

// components
import {
  CRow,
  CCol,
  CFormCheck,
  CFormLabel
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'
import FormMultiSelectInput from '../general-input-form/FormMultiSelectInput'

const AppForm = ({
  editPage,
  values,
  handleChange,
  errors,
  categoryOptions,
  handleRemoveImage,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  setOpenFilePreview,
  handleVoucherType,
  handleMultiSelect,
  selectionOptions
}) => {

  return (
    <>
      <CRow className="mb-4">
        <FormTextInput
          id='sub_category_name'
          name='sub_category_name'
          colSize='6'
          inputLabel='Game Name'
          placeholder='Enter game name...'
          value={values.sub_category_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.sub_category_name}
          invalid={errors.sub_category_name}
        />
        <FormSelectInput
          id='parent_category_id'
          name='parent_category_id'
          colSize='6'
          inputLabel='Game Type'
          selectOptions={categoryOptions}
          value={values.parent_category_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.parent_category_id}
          invalid={errors.parent_category_id}
        />
      </CRow>
      <CRow className="mb-4">
        <CCol lg='6'>
          <CFormLabel>
            Voucher Type:
          </CFormLabel>
          <CFormCheck
            name="redeemable-voucher"
            type="radio"
            className='mt-2'
            label="Redeemable Voucher"
            checked={values.is_voucher === false}
            onChange={(e) => handleVoucherType(e.target.name)}
          />
          <CFormCheck
            name="product-voucher"
            type="radio"
            className='mt-2'
            label="Product Voucher"
            checked={values.is_voucher === true}
            onChange={(e) => handleVoucherType(e.target.name)}
          />
        </CCol>
        {/* {values.game_type === 'product-voucher' && (
          <CCol lg='6'>
            <FormMultiSelectInput
              id='selected_servers'
              name='selected_servers'
              inputLabel='Select Servers'
              placeholder='Please select server...'
              colSize='12'
              className='mb-3'
              selectionOptions={selectionOptions}
              value={values.selected_servers}
              handleMultiSelect={handleMultiSelect}
              feedbackInvalid={errors.selected_servers}
              invalid={errors.selected_servers}
            />
          </CCol>
        )} */}
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='sub_category_desc'
          name='sub_category_desc'
          colSize='6'
          inputLabel='Game Description'
          // textDescription='Maximum description text is 300 characters'
          placeholder='Enter game description...'
          rows='10'
          value={values.sub_category_desc}
          handleTextAreaInput={handleChange}
        />
        {values.sub_category_images.length > 0 ? (
          <FormImageThumbnail
            editPage={editPage}
            name='sub_category_images'
            inputLabel='Banner Game Image'
            colSize='6'
            productImages={values.sub_category_images}
            handleChangeImage={handleChangeImage}
            handleRemoveImage={handleRemoveImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            name='sub_category_images'
            colSize='6'
            inputLabel='Banner Game Image'
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            multiple={false}
          />
        )}
      </CRow>
    </>
  )
}

export default AppForm
