import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-product/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormFirst from 'src/components/form/general-input-form/ActionButtonFormFirst'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect } from 'src/helpers/format-options'

const EditProduct = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [productDetails, setProductDetails] = useState({})
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)
  const [openFormProductVariant, setOpenFormProductVariant] = useState(false)
  const [openFormProductCombination, setOpenFormProductCombination] = useState(false)
  const [inputVariant, setInputVariant] = useState('')
  const [subCategoryDetails, setSubCategoryDetails] = useState('')


  const formik = useFormik({
    initialValues: {
      product_name: productDetails.name,
      category_id: productDetails.sub_category?.category_id,
      subcategory_id: productDetails.sub_category?.id,
      product_desc: productDetails.description,
      product_quantity: productDetails.qty,
      product_price: productDetails.price,
      // product_sale_price: productDetails.sale_price,
      is_product_voucher: productDetails.is_product_voucher,
      product_images: productDetails.images?.length > 0 ? [productDetails.images[0]] : [],
      // product_variants: [],
      // product_combinations: []
    },
    validationSchema: Yup.object().shape({
      product_name: Yup.string().required("Please enter voucher name."),
      category_id: Yup.string().required("Please select game type."),
      subcategory_id: Yup.string().required("Please select game."),
      product_desc: Yup.string().required("Please enter voucher description."),
      product_quantity: Yup.string().required("Please enter voucher quantity."),
      product_price: Yup.string().required("Please enter voucher price."),
      product_images: Yup.mixed().required("Please attach banner voucher images")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handlePublish(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchAllCategories()
    fetchProductDetails()
  }, []);

  useEffect(() => {
    if (formik.values.category_id !== '') {
      fetchAllSubCategoriesByCategoryId(formik.values.category_id)
    }
  }, [formik.values.category_id])

  useEffect(() => {
    if (formik.values.subcategory_id !== '') {
      fetchSubCategoryById(formik.values.subcategory_id)
    }
  }, [formik.values.subcategory_id]);

  useEffect(() => {
    if (subCategoryDetails && subCategoryDetails.is_subcategory_voucher === true) {
      formik.setValues((values) => ({
        ...values,
        is_product_voucher: true
      }), false)
    }
  }, [subCategoryDetails]);

  const fetchProductDetails = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/products/id/${id}`,
    })
      .then(({ data }) => {
        setProductDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const fetchAllCategories = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/categories/all",
    })
      .then(({ data }) => {
        setCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllSubCategoriesByCategoryId = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/sub/category/${id}`,
    })
      .then(({ data }) => {
        setSubCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchSubCategoryById = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/sub/id/${id}`,
    })
      .then(({ data }) => {
        setSubCategoryDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleInputValue = (values, isDraft) => {
    const objectPayload = {}

    objectPayload.name = values.product_name
    objectPayload.description = values.product_desc
    objectPayload.is_active = isDraft ? false : true
    objectPayload.is_draft = isDraft
    objectPayload.qty = parseInt(values.product_quantity || '0')
    objectPayload.price = parseInt(values.product_price || '0')
    objectPayload.sub_category_id = parseInt(values.subcategory_id)
    objectPayload.status = isDraft ? 'draft' : 'active'
    objectPayload.stock_threshold = parseInt('5')
    objectPayload.is_product_voucher = values.is_product_voucher

    return objectPayload
  }

  const handleInputImageValue = () => {
    const formData = new FormData()

    formData.append('file', formik.values.product_images[0])
    // if (values.product_variants.length > 0) {
    //   formData.append('product_variants', values.product_variants)
    // }
    // if (values.product_combinations.length > 0) {
    //   formData.append('product_combinations', values.product_combinations)
    // }

    return formData
  }

  const handleEdit = (payload, isDraft) => {
    if (isDraft) {
      setIsLoadingDraft(true)
    } else {
      setIsLoadingPublish(true)
    }
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/products/update/${id}`,
      data: payload
    })
      .then(({ data1 }) => {
        if (formik.values.product_images.length > 0 && formik.values.product_images[0] instanceof File) {
          Axios({
            method: "post",
            url: `/api/ecommerce/v1/products/image/delete/${productDetails?.images[0]?.id}`,
          })
            .then(({ data2 }) => {
              const formData = handleInputImageValue()
              Axios({
                method: "post",
                url: `/api/ecommerce/v1/products/upload/image/${id}`,
                data: formData
              })
                .then(({ data3 }) => {
                  navigate('/products/list')
                })
                .catch((err) => {
                  console.error(err);
                })
                .finally(() => {
                  if (isDraft) {
                    setIsLoadingDraft(false)
                  } else {
                    setIsLoadingPublish(false)
                  }
                })
            })
            .catch((err) => {
              console.error(err);
            })
        } else {
          navigate('/products/list')
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handlePublish = (values) => {
    const inputPayload = handleInputValue(values, false)
    handleEdit(inputPayload, false)
  }

  const handleDraft = () => {
    const inputPayload = handleInputValue(formik.values, true)
    handleEdit(inputPayload, true)
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      product_images: values.product_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    // files.forEach(file => {
    //   if (file && typeof file !== 'undefined') {
    //     attachments.push(file)
    //   }
    // })
    attachments.push(files[0])
    formik.setValues((values) => ({
      ...values,
      product_images: attachments
    }), false)
  }

  const handleImagesAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    // files.forEach(file => {
    //   if (file && typeof file !== 'undefined') {
    //     attachments.push(file)
    //   }
    // })
    attachments.push(files[0])
    formik.setValues((values) => ({
      ...values,
      product_images: attachments
    }), false)
  }

  const handleVoucherType = (value) => {
    if (value === 'redeemable-voucher') {
      formik.setValues((values) => ({
        ...values,
        is_product_voucher: false
      }), false)
    }
    if (value == 'product-voucher') {
      formik.setValues((values) => ({
        ...values,
        is_product_voucher: true
      }), false)
    }
  }

  // function handler product variant 
  const handleInputVariantType = (e) => {
    if (e.key === 'Enter') {
      formik.setValues((values) => ({
        ...values,
        product_variants: [
          ...values.product_variants,
          ...[{
            name: capitalizeEachWord(inputVariant),
            options: [{
              option_name: '',
              option_images: []
            }]
          }]
        ]
      }), false)
      setInputVariant('')
    }
    if (e.key === 'Escape') {
      setInputVariant('')
    }
  }

  const handleRemoveInputVariantType = (variant) => {
    formik.setValues((values) => ({
      ...values,
      product_variants: values.product_variants.filter((item) => item !== variant)
    }), false)
  }

  const handleAddVariantOptions = (variant, variantIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (item.name == variant.name && itemIndex == variantIndex) {
          return {
            ...item,
            options: [
              ...item.options,
              ...[{
                option_name: '',
                option_images: []
              }]
            ]
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleRemoveVariantOptions = (variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.filter((element, elementIndex) => elementIndex !== optionIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleTextVariantOptions = (name, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_name: name
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleImageVariantOptions = (e, variantIndex, option, optionIndex) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
    }

    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_images: attachments
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleRemoveImageVariantOptions = (image, imageIndex, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_images: element.option_images.filter((optionImage, optionImageIndex) => optionImageIndex !== optionIndex)
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  // function handler product combination
  const handleAddProductCombination = () => {
    formik.setValues((values) => ({
      ...values,
      product_combinations: [
        ...values.product_combinations,
        ...[{
          name: `Product Combination ${values.product_combinations.length + 1}`,
          variant_type: '',
          variant_option: '',
          price: '',
          quantity: '',
          images: []
        }]
      ]
    }), false)
  }

  const handleRemoveProductCombination = (index) => {
    formik.setValues((values) => ({
      ...values,
      product_combinations: values.product_combinations.filter((item, itemIndex) => itemIndex !== index)
    }), false)
  }

  const handleVariantTypeProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            variant_type: value,
            variant_option: ''
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleVariantOptionProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            variant_option: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handlePriceProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            price: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleStockProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            quantity: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleImageProductCombinations = (e, combinationIndex) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
    }

    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            images: attachments
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleRemoveImageProductCombinations = (image, imageIndex, combinationIndex) => {
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            images: item.images.filter((combinationImage, combinationImageIndex) => combinationImageIndex !== imageIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Edit Voucher</h4>}
        bodyContent={
          <AppForm
            editPage
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
            subCategoryDetails={subCategoryDetails}
            handleRemoveImage={handleRemoveImage}
            handleChangeImage={handleImagesAttachment}
            handleFileInput={handleImagesAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            setOpenFilePreview={setOpenFilePreview}
            handleVoucherType={handleVoucherType}
            openFormProductVariant={openFormProductVariant}
            setOpenFormProductVariant={setOpenFormProductVariant}
            openFormProductCombination={openFormProductCombination}
            setOpenFormProductCombination={setOpenFormProductCombination}
            setInputVariant={setInputVariant}
            inputVariant={inputVariant}
            handleInputVariantType={handleInputVariantType}
            handleAddVariantOptions={handleAddVariantOptions}
            handleRemoveInputVariantType={handleRemoveInputVariantType}
            handleRemoveVariantOptions={handleRemoveVariantOptions}
            handleTextVariantOptions={handleTextVariantOptions}
            handleImageVariantOptions={handleImageVariantOptions}
            handleRemoveImageVariantOptions={handleRemoveImageVariantOptions}
            handleAddProductCombination={handleAddProductCombination}
            handleRemoveProductCombination={handleRemoveProductCombination}
            handleVariantTypeProductCombinations={handleVariantTypeProductCombinations}
            handleVariantOptionProductCombinations={handleVariantOptionProductCombinations}
            handlePriceProductCombinations={handlePriceProductCombinations}
            handleStockProductCombinations={handleStockProductCombinations}
            handleRemoveImageProductCombinations={handleRemoveImageProductCombinations}
            handleImageProductCombinations={handleImageProductCombinations}
          />
        }
        footerContent={
          <ActionButtonFormFirst
            isDraft={productDetails?.is_draft}
            isLoadingDraft={isLoadingDraft}
            isLoadingPublish={isLoadingPublish}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/products/list')}
            onDraft={() => handleDraft()}
            onPublish={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default EditProduct
