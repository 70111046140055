export const capitalizeFirstLetter = (string) => {
  return string
    .charAt(0)
    .toUpperCase() + string
      .slice(1)
      .toLowerCase();
}

export const capitalizeEachWord = (phrase) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getFullName = (profile) => {
  return (profile?.first_name ? profile?.first_name : "") + " " + (profile?.last_name ? profile?.last_name : "");
}

export const random = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const toKebabCase = (string) => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}