import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard.js'
import AppCardHeader from 'src/components/card/AppCardHeader'
import AppDetails from 'src/components/details/coupon-details/AppDetails'
import AppFirstDetails from 'src/components/details/coupon-details/AppFirstDetails'
import AppSecondDetails from 'src/components/details/coupon-details/AppSecondDetails'

// helpers
import Axios from 'src/helpers/axios'

const CouponDetails = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [couponDetails, setCouponDetails] = useState('')
  const [couponImages, setCouponImages] = useState([])

  const handleCardHeaderButton = () => {
    navigate(`/coupons/edit/${id}`)
    window.location.reload();
  }

  useEffect(() => {
    fetchCouponDetails()
  }, [id]);

  const fetchCouponDetails = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/coupons/products/id/${id}`,
    })
      .then(({ data }) => {
        setCouponDetails(data)
        if (data.image_url && data.image_url !== '') {
          setCouponImages([data.image_url])
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <AppCard
      className='mt-4 mb-4'
      headerTitle={
        <AppCardHeader
          headerTitle={<h4>Coupon Details</h4>}
          headerButton="Edit"
          handleCardHeaderButton={handleCardHeaderButton}
        />
      }
      bodyContent={
        <AppDetails
          couponDetails={couponDetails}
          couponImages={couponImages}
        />
      }
    />
  )
}

export default CouponDetails
