import React, { useState } from 'react'

// components
import AppFirstForm from './AppFirstForm'
import AppSecondForm from './AppSecondForm'
import AppThirdForm from './AppThirdForm'

const AppForm = ({
  editPage,
  values,
  errors,
  handleChange,
  categoryOptions,
  subCategoryOptions,
  subCategoryDetails,
  handleRemoveImage,
  handleFileInput,
  handleFileOnDrop,
  handleChangeImage,
  setOpenFilePreview,
  handleVoucherType,
  openFormProductVariant,
  setOpenFormProductVariant,
  openFormProductCombination,
  setOpenFormProductCombination,
  setInputVariant,
  inputVariant,
  handleInputVariantType,
  handleAddVariantOptions,
  handleRemoveInputVariantType,
  handleRemoveVariantOptions,
  handleTextVariantOptions,
  handleImageVariantOptions,
  handleRemoveImageVariantOptions,
  handleAddProductCombination,
  handleRemoveProductCombination,
  handleVariantTypeProductCombinations,
  handleVariantOptionProductCombinations,
  handlePriceProductCombinations,
  handleSalePriceProductCombinations,
  handleStockProductCombinations,
  handleRemoveImageProductCombinations,
  handleImageProductCombinations,
}) => {

  return (
    <>
      <AppFirstForm
        editPage={editPage}
        categoryOptions={categoryOptions}
        subCategoryOptions={subCategoryOptions}
        subCategoryDetails={subCategoryDetails}
        openFormProductVariant={openFormProductVariant}
        setOpenFormProductVariant={setOpenFormProductVariant}
        openFormProductCombination={openFormProductCombination}
        setOpenFormProductCombination={setOpenFormProductCombination}
        setOpenFilePreview={setOpenFilePreview}
        handleVoucherType={handleVoucherType}
        handleChange={handleChange}
        values={values}
        errors={errors}
        handleChangeImage={handleChangeImage}
        handleRemoveImage={handleRemoveImage}
        handleFileInput={handleFileInput}
        handleFileOnDrop={handleFileOnDrop}
      />
      {/* {openFormProductVariant && (
        <AppSecondForm
          editPage={editPage}
          values={values}
          errors={errors}
          openFormProductCombination={openFormProductCombination}
          setOpenFormProductCombination={setOpenFormProductCombination}
          setOpenFilePreview={setOpenFilePreview}
          setInputVariant={setInputVariant}
          inputVariant={inputVariant}
          handleInputVariantType={handleInputVariantType}
          handleAddVariantOptions={handleAddVariantOptions}
          handleRemoveInputVariantType={handleRemoveInputVariantType}
          handleRemoveVariantOptions={handleRemoveVariantOptions}
          handleTextVariantOptions={handleTextVariantOptions}
          handleImageVariantOptions={handleImageVariantOptions}
          handleRemoveImageVariantOptions={handleRemoveImageVariantOptions}
        />
      )}
      {openFormProductCombination && values.product_variants.length > 0 && (
        <AppThirdForm
          editPage={editPage}
          values={values}
          errors={errors}
          setOpenFilePreview={setOpenFilePreview}
          handleAddProductCombination={handleAddProductCombination}
          handleRemoveProductCombination={handleRemoveProductCombination}
          handleVariantTypeProductCombinations={handleVariantTypeProductCombinations}
          handleVariantOptionProductCombinations={handleVariantOptionProductCombinations}
          handlePriceProductCombinations={handlePriceProductCombinations}
          handleSalePriceProductCombinations={handleSalePriceProductCombinations}
          handleStockProductCombinations={handleStockProductCombinations}
          handleRemoveImageProductCombinations={handleRemoveImageProductCombinations}
          handleImageProductCombinations={handleImageProductCombinations}
        />
      )} */}
    </>
  )
}

export default AppForm
