import React, { useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// component
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'

const Register = () => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Please enter your first name."),
      last_name: Yup.string().required("Please enter your last name."),
      username: Yup.string().required("Please enter your username."),
      email: Yup.string().email('Invalid email address').required("Please enter your email address."),
      password: Yup.string().min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol')
        .required("Please enter your password.")
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      // handleSave(values)
      setSubmitting(false)
    },
  });

  const handleInputValue = (values) => {
    const payload = {}

    payload.first_name = values.first_name
    payload.last_name = values.last_name
    payload.username = values.username
    payload.email = values.email
    payload.password = values.password

    return payload
  }

  const handlePost = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: '/api/ecommerce/v1/user/register',
      data: payload
    })
      .then(({ data }) => {
        navigate('/login')
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleSave = (values) => {
    const inputPayload = handleInputValue(values)
    handlePost(inputPayload)
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <h1>Register</h1>
                <p className="text-medium-emphasis">Create your account</p>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    id='first_name'
                    name='first_name'
                    placeholder="First Name"
                    value={formik.values.first_name}
                    onChange={(e) => formik.handleChange(e)}
                    invalid={formik.errors.first_name}
                    feedbackInvalid={formik.errors.first_name}
                    autoComplete='off'
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    id='last_name'
                    name='last_name'
                    placeholder="Last Name"
                    value={formik.values.last_name}
                    onChange={(e) => formik.handleChange(e)}
                    invalid={formik.errors.last_name}
                    feedbackInvalid={formik.errors.last_name}
                    autoComplete='off'
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilUser} />
                  </CInputGroupText>
                  <CFormInput
                    id='username'
                    name='username'
                    placeholder="Username"
                    value={formik.values.username}
                    onChange={(e) => formik.handleChange(e)}
                    invalid={formik.errors.username}
                    feedbackInvalid={formik.errors.username}
                    autoComplete='off'
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>@</CInputGroupText>
                  <CFormInput
                    type='email'
                    id='email'
                    name='email'
                    placeholder="Email Address"
                    value={formik.values.email}
                    onChange={(e) => formik.handleChange(e)}
                    invalid={formik.errors.email}
                    feedbackInvalid={formik.errors.email}
                    autoComplete='off'
                  />
                </CInputGroup>
                <CInputGroup className="mb-3">
                  <CInputGroupText>
                    <CIcon icon={cilLockLocked} />
                  </CInputGroupText>
                  <CFormInput
                    type="password"
                    placeholder="Password"
                    id='password'
                    name='password'
                    value={formik.values.password}
                    onChange={(e) => formik.handleChange(e)}
                    invalid={formik.errors.password}
                    feedbackInvalid={formik.errors.password}
                    autoComplete='off'
                  />
                </CInputGroup>
                <div className="d-grid">

                  <CButton
                    color="dark"
                    onClick={() => formik.handleSubmit()}
                  >
                    {isLoading && (
                      <CSpinner
                        className='me-2'
                        component="span"
                        color="light"
                        size="sm"
                        aria-hidden="true"
                      />
                    )}
                    Create Account
                  </CButton>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Register
