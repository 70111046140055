import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

// components
import {
  CSmartTable,
  CBadge,
  CButtonGroup,
  CButton,
  CImage,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CSmartPagination,
} from '@coreui/react-pro'

// icons 
import ThreeDotsIconMenu from '../assets/icons/ThreeDotsOptionsIcon.png'

// styling
import { styleButtonVars5 } from './custom-styles/buttonStyles'

// helpers
import { productStatus } from 'src/helpers/status-badge'
import { orderStatus } from 'src/helpers/status-badge'

const AppTable = ({
  productListPage,
  categoryListPage,
  subCategoryListPage,
  purchaseListPage,
  customerListPage,
  couponListPage,
  setShowConfirmDelete,
  selectable,
  tableColumns,
  tableContent,
  contentPerPage,
  activeContentPage,
  contentCount,
  setActiveContentPage,
  searchContentFilter,
  setSearchContentFilter,
  setContentPerPage,
  columnContentSorter,
  setColumnContentSorter,
}) => {
  const navigate = useNavigate()

  return (
    <>
      <CSmartTable
        columns={tableColumns}
        // columnSorter={{
        //   external: true,
        //   resetable: true
        // }}
        sorterValue={columnContentSorter}
        // onSorterChange={(sorter) => setColumnContentSorter(sorter)}
        items={tableContent}
        itemsPerPage={contentPerPage}
        itemsPerPageSelect
        onItemsPerPageChange={(itemsPerPage) => setContentPerPage(itemsPerPage)}
        // pagination={{
        //   external: true,
        // }}
        // paginationProps={{
        //   align: 'center',
        //   activePage: activeContentPage,
        //   pages: Math.ceil(contentCount / contentPerPage) || 1,
        // }}
        // onActivePageChange={(activePage) => setActiveContentPage(activePage)}
        tableProps={{
          className: 'app-table-content-list',
          hover: true,
          responsive: true,
          style: {
            cursor: 'pointer'
          }
        }}
        tableHeadProps={{
          className: 'app-table-header'
        }}
        selectable={selectable}
        // clickableRows
        // onRowClick={(item) => handleOnRowClick(item)}
        // tableFilter={tableFilter}
        // tableFilterValue={searchContentFilter}
        // tableFilterPlaceholder='Search...'
        // tableFilterLabel='Title:'
        // onTableFilterChange={(filter) => setSearchContentFilter(filter)}
        scopedColumns={{
          product_main_image: (item) => (
            <td>
              {item?.product_main_image ? <CImage fluid rounded src={item?.product_main_image} height={130} width={80} /> : 'Not Available'}
            </td>
          ),
          status: (item) => (
            <td>
              <CBadge className='table-badge-status-content' color={productStatus(item?.status)} shape="rounded-pill">
                {item?.status?.toString().toUpperCase()}
              </CBadge>
            </td>
          ),
          payment_status: (item) => (
            <td>
              <CBadge className='table-badge-status-content' color={orderStatus(item?.payment_status)} shape="rounded-pill">
                {item?.payment_status?.toString().toUpperCase()}
              </CBadge>
            </td>
          ),
          createdAt: (item) => (
            <td>
              {moment(item?.createdAt).format('DD/MM/YYYY')}
            </td>
          ),
          transaction_date: (item) => (
            <td>
              {moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}
            </td>
          ),
          description: (item) => (
            <td>
              {item?.description?.length > 70 ? `${item?.description?.slice(0, 70)}...` : item?.description}
            </td>
          ),
          action: (item) => (
            <td>
              <CDropdown>
                <CButton
                  style={styleButtonVars5}
                  href={
                    productListPage ? `/products/details/${item.id}`
                      : categoryListPage ? `/products/category/details/${item.id}`
                        : subCategoryListPage ? `/products/sub-category/details/${item.id}`
                          : purchaseListPage ? `/purchases/details/${item.id}`
                            : customerListPage ? `/customers/details/${item.id}`
                              : couponListPage ? `/coupons/details/${item.id}`
                                : '#'
                  }
                >
                  View
                </CButton>
                {(productListPage || categoryListPage || subCategoryListPage || purchaseListPage) && (
                  <>
                    <CDropdownToggle
                      color="white"
                      caret={false}
                      style={{
                        border: "none",
                        boxShadow: "none",
                        background: "#FFF",
                        // borderRadius: ".5rem",
                        borderBottomRightRadius: ".3rem",
                        borderTopRightRadius: ".3rem",
                      }}
                      split
                    >
                      <CImage fluid src={ThreeDotsIconMenu} />
                    </CDropdownToggle>
                    <CDropdownMenu>
                      {(productListPage || categoryListPage || subCategoryListPage || couponListPage) && (
                        <>
                          <CDropdownItem
                            href={
                              productListPage ? `/products/edit/${item.id}`
                                : categoryListPage ? `/products/category/edit/${item.id}`
                                  : subCategoryListPage ? `/products/sub-category/edit/${item.id}`
                                    : couponListPage ? `/coupons/edit/${item.id}`
                                      : '#'
                            }
                          >
                            Edit {
                              productListPage ? 'Voucher'
                                : categoryListPage ? 'Game Type'
                                  : subCategoryListPage ? 'Game'
                                    : couponListPage ? 'Coupon'
                                      : ''
                            }
                          </CDropdownItem>
                          <CDropdownItem
                            onClick={() => setShowConfirmDelete({ show: true, item: item })}
                          >
                            Delete {
                              productListPage ? 'Voucher'
                                : categoryListPage ? 'Game Type'
                                  : subCategoryListPage ? 'Game'
                                    : couponListPage ? 'Coupon'
                                      : ''
                            }
                          </CDropdownItem>
                        </>
                      )}
                      {/* {productListPage && (
                      <CDropdownItem
                        onClick={() => console.log('Duplicate')}
                      >
                        Duplicate Product
                      </CDropdownItem>
                    )} */}
                      {purchaseListPage && (
                        <CDropdownItem
                          href={item?.invoice?.file_url}
                          download={`INVOICE ${item?.transaction_id}`}
                        >
                          Download Receipt
                        </CDropdownItem>
                      )}
                    </CDropdownMenu>
                  </>
                )}
              </CDropdown>
            </td>
          )
        }}
      />
      <CSmartPagination
        align="center"
        activePage={activeContentPage}
        pages={Math.ceil(contentCount / contentPerPage) || 1}
        onActivePageChange={(activePage) => setActiveContentPage(activePage)}
        p
      />
    </>
  )
}

export default AppTable