import React from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormCheck,
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormMultiSelectInput from '../general-input-form/FormMultiSelectInput'
import AppTextColumnDetails from 'src/components/details/general-details/AppTextColumnDetails'

// helpers
import { couponTypeText } from 'src/helpers/status-badge'

const AppSecondForm = ({
  editPage,
  values,
  handleChange,
  handleMultiSelect,
  handleApplyCouponTo,
  errors,
}) => {
  const selectionOptions = [{
    label: 'Category',
    options: [
      {
        value: 1,
        text: 'Category A',
      },
      {
        value: 2,
        text: 'Category B',
      },
      {
        value: 3,
        text: 'Category C',
      },
    ],
  }, {
    label: 'Sub Category',
    options: [
      {
        value: 4,
        text: 'Sub Category A',
      },
      {
        value: 5,
        text: 'Sub Category B',
      },
      {
        value: 6,
        text: 'Sub Category C',
      },
    ],
  }, {
    label: 'Product',
    options: [
      {
        value: 7,
        text: 'Product A',
      },
      {
        value: 8,
        text: 'Product B',
      },
      {
        value: 9,
        text: 'Product C',
      },
    ],
  }];

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Coupon Type"
          subtitle={values.coupon_type && values.coupon_type !== '' ? couponTypeText(values.coupon_type) : ''}
        />
        <FormTextInput
          id='user_per_coupon'
          name='uses_per_coupon'
          colSize='3'
          inputLabel='Uses Per Coupon'
          placeholder='Enter uses per coupon...'
          className='mb-3'
          value={values.uses_per_coupon}
          handleTextInput={handleChange}
          feedbackInvalid={errors.uses_per_coupon}
          invalid={errors.uses_per_coupon}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Coupon Value"
          subtitle={values.coupon_value && values.coupon_value !== '' ? `${'IDR'} ${values.coupon_value}` : ''}
        />
        <FormTextInput
          id='user_per_customer'
          name='uses_per_customer'
          colSize='3'
          inputLabel='Uses Per Customer'
          placeholder='Enter uses per customer...'
          className='mb-3'
          value={values.uses_per_customer}
          handleTextInput={handleChange}
          feedbackInvalid={errors.uses_per_customer}
          invalid={errors.uses_per_customer}
        />
      </CRow>
      <CRow>
        <CCol className='mb-3' lg='3'>
          <CFormLabel>
            Apply Coupon To:
          </CFormLabel>
          <CFormCheck
            name="entire-product"
            type="radio"
            className='mt-2'
            label="Entire Product"
            checked={values.apply_coupon_to == "entire-product"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
          <CFormCheck
            name="selected-product"
            type="radio"
            className='mt-2'
            label="Selected Product"
            checked={values.apply_coupon_to == "selected-product"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
        </CCol>
        {values.apply_coupon_to === 'selected-product' && (
          <CCol lg='9'>
            <FormMultiSelectInput
              id='coupon_products'
              name='coupon_products'
              inputLabel='Select Products'
              placeholder='Please select product for this coupon...'
              colSize='12'
              className='mb-3'
              selectionOptions={selectionOptions}
              value={values.coupon_products}
              handleMultiSelect={handleMultiSelect}
              feedbackInvalid={errors.coupon_products}
              invalid={errors.coupon_products}
            />
          </CCol>
        )}
      </CRow>
    </>
  )
}

export default AppSecondForm
