import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-sub-category/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormSecond from 'src/components/form/general-input-form/ActionButtonFormSecond'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect } from 'src/helpers/format-options'

const EditSubCategory = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [categoryOptions, setCategoryOptions] = useState('')
  const [subCategoryDetails, setSubCategoryDetails] = useState('')
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      sub_category_name: subCategoryDetails.name,
      sub_category_desc: subCategoryDetails.description,
      parent_category_id: subCategoryDetails.category_id,
      sub_category_images: subCategoryDetails.image_url && subCategoryDetails.image_url !== '' ? [subCategoryDetails.image_url] : [],
      is_voucher: subCategoryDetails.is_subcategory_voucher,
      selected_servers: []
    },
    validationSchema: Yup.object().shape({
      sub_category_name: Yup.string().required("Please enter sub category name."),
      parent_category_id: Yup.string().required("Please choose parent category.")
    }),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      handleSave(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchAllCategories()
    fetchSubCategoryDetails()
  }, [id]);

  const fetchAllCategories = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/categories/all",
    })
      .then(({ data }) => {
        setCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchSubCategoryDetails = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/sub/id/${id}`,
    })
      .then(({ data }) => {
        setSubCategoryDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleInputValue = (values) => {
    const formData = new FormData()

    formData.append('name', values.sub_category_name)
    formData.append('category_id', values.parent_category_id)
    formData.append('is_subcategory_voucher', values.is_voucher)
    if (values.sub_category_images.length > 0) {
      formData.append('file', values.sub_category_images[0])
    }
    if (values.sub_category_desc !== '') {
      formData.append('description', values.sub_category_desc)
    }

    return formData
  }

  const handleEdit = (payload) => {
    setIsLoading(true)
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/categories/sub/update/${id}`,
      data: payload
    })
      .then(({ data }) => {
        navigate('/products/sub-category/list')
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false))
  }

  const handleSave = (values) => {
    const inputPayload = handleInputValue(values)
    handleEdit(inputPayload)
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      sub_category_images: values.sub_category_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        sub_category_images: attachments
      }), false)
    }
  }

  const handleImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        sub_category_images: attachments
      }), false)
    }
  }

  const handleVoucherType = (value) => {
    if (value === 'redeemable-voucher') {
      formik.setValues((values) => ({
        ...values,
        is_voucher: false
      }), false)
    }
    if (value == 'product-voucher') {
      formik.setValues((values) => ({
        ...values,
        is_voucher: true
      }), false)
    }
  }

  const handlSelectedServer = (value) => {
    formik.setValues((values) => ({
      ...values,
      selected_server: value
    }), false)
  }

  const selectionOptions = [{
    label: 'Server',
    options: [
      {
        value: 1,
        text: 'Server ABC',
      },
      {
        value: 2,
        text: 'Server DEF',
      },
      {
        value: 3,
        text: 'Server GHI',
      },
      {
        value: 4,
        text: 'Server JKL',
      },
      {
        value: 5,
        text: 'Server MNO',
      },
      {
        value: 6,
        text: 'Server PQR',
      },
    ],
  }];

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Edit Game</h4>}
        bodyContent={
          <AppForm
            editPage
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            categoryOptions={categoryOptions}
            handleChangeImage={handleImageAttachment}
            handleFileInput={handleImageAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            handleRemoveImage={handleRemoveImage}
            setOpenFilePreview={setOpenFilePreview}
            handleVoucherType={handleVoucherType}
            handleMultiSelect={handlSelectedServer}
            selectionOptions={selectionOptions}
          />
        }
        footerContent={
          <ActionButtonFormSecond
            isLoading={isLoading}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/products/sub-category/list')}
            onSave={() => formik.handleSubmit()}
          />
        }
      />
    </>

  )
}

export default EditSubCategory
