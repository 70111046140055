export const columnProductList = [
  {
    key: "product_main_image",
    label: "Banner Voucher",
    filter: false,
    sorter: false
  },
  {
    key: "name",
    label: "Voucher Name",
    filter: false,
    sorter: false,
  },
  {
    key: "category_name",
    label: "Game Type",
    filter: false,
    sorter: false
  },
  {
    key: "qty",
    label: "Stock",
    filter: false,
    sorter: false,
  },
  {
    key: "price",
    label: "Unit Price",
    filter: false,
    sorter: false
  },
  {
    key: "status",
    label: "Status",
    filter: false,
    sorter: false
  },
  {
    key: "createdAt",
    label: "Date Added",
    filter: false,
    sorter: false
  },
  {
    key: "action",
    label: "",
    filter: false,
    sorter: false
  },
];

export const columnCategoryList = [
  {
    key: "name",
    label: "Game Type",
    filter: false,
    sorter: false
  },
  {
    key: "description",
    label: "Game Type Description",
    filter: false,
    sorter: false
  },
  {
    key: "sub_category_count",
    label: "Game List",
    filter: false,
    sorter: false,
  },
  {
    key: "createdAt",
    label: "Date Added",
    filter: false,
    sorter: false
  },
  {
    key: "action",
    label: "",
    filter: false,
    sorter: false
  },
];

export const columnSubCategoryList = [
  {
    key: "name",
    label: "Game Name",
    filter: false,
    sorter: false
  },
  {
    key: "category_name",
    label: "Game Type",
    filter: false,
    sorter: false,
  },
  {
    key: "description",
    label: "Game Description",
    filter: false,
    sorter: false
  },
  {
    key: "createdAt",
    label: "Date Added",
    filter: false,
    sorter: false
  },
  {
    key: "action",
    label: "",
    filter: false,
    sorter: false
  },
];

export const columnPurchaseList = [
  {
    key: "transaction_id",
    label: "Purchase ID",
    filter: false,
    sorter: false,
  },
  {
    key: "customer_name",
    label: "Customer Name",
    filter: false,
    sorter: false
  },
  {
    key: "transaction_date",
    label: "Purchase Date / Time",
    filter: false,
    sorter: false
  },
  {
    key: "transaction_amount",
    label: "Amount Purchase",
    filter: false,
    sorter: false
  },
  {
    key: "payment_type",
    label: "Payment Type",
    filter: false,
    sorter: false
  },
  {
    key: "payment_status",
    label: "Status",
    filter: false,
    sorter: false
  },
  {
    key: "action",
    label: "",
    filter: false,
    sorter: false
  },
];

export const columnCustomerList = [
  {
    key: "customer_id",
    label: "Customer ID",
    filter: false,
    sorter: false,
  },
  {
    key: "full_name",
    label: "Customer Name",
    filter: false,
    sorter: false
  },
  {
    key: "email",
    label: "Email Address",
    filter: false,
    sorter: false
  },
  {
    key: "phone_number",
    label: "Phone Number",
    filter: false,
    sorter: false
  },
  {
    key: "createdAt",
    label: "Registered On",
    filter: false,
    sorter: false
  },
  {
    key: "action",
    label: "",
    filter: false,
    sorter: false
  },
];

export const columnCouponList = [
  {
    key: "coupon_uuid",
    label: "Coupon ID",
    filter: false,
    sorter: false,
  },
  {
    key: "name",
    label: "Coupon Name",
    filter: false,
    sorter: false
  },
  {
    key: "type",
    label: "Coupon Type",
    filter: false,
    sorter: false
  },
  {
    key: "code",
    label: "Coupon Code",
    filter: false,
    sorter: false
  },
  {
    key: "quota",
    label: "Quota",
    filter: false,
    sorter: false
  },
  {
    key: "createdAt",
    label: "Created On",
    filter: false,
    sorter: false
  },
  {
    key: "action",
    label: "",
    filter: false,
    sorter: false
  },
];