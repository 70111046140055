import React from 'react'

// components
import {
  CCol,
  CFormLabel,
  CFormInput,
  CRow,
  CImage,
  CButton,
  CCard,
  CCardBody,
} from '@coreui/react-pro'

// icons
import TrashIcon from '../../../assets/icons/TrashIcon.png'
import ExampleGame1 from '../../../assets/images/ExampleGame1.png'
import AddMoreIconGrey from '../../../assets/icons/AddMoreIconGrey.png'

// styling 
import { styleButtonVars1 } from 'src/components/custom-styles/buttonStyles'
import { styleLabelVars1 } from 'src/components/custom-styles/labelStyles'

const FormImageThumbnail = ({
  editPage,
  formProduct,
  colSize,
  inputLabel,
  className,
  classNameRow,
  productImages,
  handleChangeImage,
  handleRemoveImage,
  setOpenFilePreview,
  variantTypeIndex,
  variantOption,
  variantOptionIndex,
  combinationIndex
}) => {

  return (
    <CCol lg={colSize} className={className}>
      {inputLabel && (
        <CFormLabel>
          {inputLabel}
        </CFormLabel>
      )}
      {productImages?.length > 0 && (
        <CRow className={classNameRow}>
          {productImages.map((image, index) => {
            return (
              <CCard style={{ width: formProduct ? '14rem' : '22rem', height: 'fit-content' }} className='mb-2 me-2' key={index}>
                <CImage
                  fluid
                  rounded
                  align="center"
                  src={image instanceof File ? URL.createObjectURL(image) : image?.image_url ? image?.image_url : image}
                  className='mt-2'
                  style={{
                    maxHeight: formProduct ? '6.25rem' : '15rem'
                  }}
                  onClick={() => setOpenFilePreview(image)}
                />
                <CCardBody>
                  <div className="d-grid gap-2 mx-auto text-center justify-content-center">
                    {editPage && (
                      <>
                        <CFormInput
                          id="upload_single_image_thumbnail_product"
                          className="position-absolute invisible"
                          type="file"
                          onChange={(e) => handleChangeImage(e, (variantTypeIndex || combinationIndex), variantOption, variantOptionIndex)}
                          multiple={false}
                          accept='.jpeg, .png, .gif'
                        />
                        <label htmlFor="upload_single_image_thumbnail_product" style={styleLabelVars1}>
                          <CImage fluid src={AddMoreIconGrey} height={15} width={15} className='me-2 mb-1' />
                          Replace Image
                        </label>
                      </>
                    )}
                    {!editPage && (
                      <CButton
                        style={styleButtonVars1}
                        onClick={() => handleRemoveImage(image, index, (variantTypeIndex || combinationIndex), variantOption, variantOptionIndex)}
                      >
                        <CImage fluid src={TrashIcon} className='me-2 mb-1' />
                        Remove
                      </CButton>
                    )}
                    {/* <CButton
                      style={styleButtonVars1}
                      onClick={() => handleRemoveImage(image, index, (variantTypeIndex || combinationIndex), variantOption, variantOptionIndex)}
                    >
                      <CImage fluid src={TrashIcon} className='me-2 mb-1' />
                      Remove
                    </CButton> */}
                  </div>
                </CCardBody>
              </CCard>
            )
          })}
        </CRow>
      )}
    </CCol>
  )
}

export default FormImageThumbnail