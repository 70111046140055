import React from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormCheck
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'
import FormSelectInput from '../general-input-form/FormSelectInput'
import FormCouponValue from '../general-input-form/FormCouponValue'
import FormDatePickerRange from '../general-input-form/FormDatePickerRange'

const AppFirstForm = ({
  editPage,
  values,
  handleChange,
  errors,
  couponTypeOptions,
  openCouponPeriod,
  setOpenCouponPeriod,
  handleRemoveImage,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  handleStartDateRange,
  handleEndDateRange,
  setOpenFilePreview,
  couponDetails
}) => {
  return (
    <CRow>
      <CCol lg='6'>
        <CRow>
          <FormTextInput
            id='coupon_name'
            name='coupon_name'
            colSize='12'
            inputLabel='Coupon Name'
            placeholder='Enter coupon name...'
            className='mb-3'
            value={values.coupon_name}
            handleTextInput={handleChange}
            feedbackInvalid={errors.coupon_name}
            invalid={errors.coupon_name}
          />
        </CRow>
        <CRow>
          <FormTextAreaInput
            id='coupon_desc'
            name='coupon_desc'
            colSize='12'
            inputLabel='Coupon Description'
            className='mb-3'
            // textDescription='Maximum description text is 300 characters'
            placeholder='Add coupon description...'
            rows='10'
            value={values.coupon_desc}
            handleTextAreaInput={handleChange}
            feedbackInvalid={errors.coupon_desc}
            invalid={errors.coupon_desc}
          />
        </CRow>
        <CRow>
          <FormTextInput
            id='coupon_code'
            name='coupon_code'
            colSize='12'
            inputLabel='Coupon Code'
            placeholder='Enter coupon code...'
            className='mb-3'
            value={values.coupon_code}
            handleTextInput={handleChange}
            feedbackInvalid={errors.coupon_code}
            invalid={errors.coupon_code}
          />
        </CRow>
        <CRow>
          {values.coupon_images.length > 0 ? (
            <FormImageThumbnail
              editPage={editPage}
              inputLabel='Banner Image'
              name='coupon_images'
              colSize='12'
              classNameRow='ms-1'
              productImages={values.coupon_images}
              handleChangeImage={handleChangeImage}
              handleRemoveImage={handleRemoveImage}
              setOpenFilePreview={setOpenFilePreview}
            />
          ) : (
            <FormImageUpload
              name='coupon_images'
              colSize='12'
              inputLabel='Banner Image'
              handleFileInput={handleFileInput}
              handleFileOnDrop={handleFileOnDrop}
              multiple={false}
            />
          )}
        </CRow>
      </CCol>
      <CCol lg='6'>
        <CRow>
          <FormSelectInput
            id='coupon_type'
            name='coupon_type'
            colSize='6'
            inputLabel='Coupon Type'
            selectOptions={couponTypeOptions}
            className='mb-3'
            value={values.coupon_type}
            handleSelectInput={handleChange}
            feedbackInvalid={errors.coupon_type}
            invalid={errors.coupon_type}
          />
        </CRow>
        {values.coupon_type && (
          <CRow>
            <FormCouponValue
              id='coupon_value'
              name='coupon_value'
              colSize='6'
              className='mb-3'
              couponType={values.coupon_type}
              value={values.coupon_value}
              handleTextInput={handleChange}
              handleShippingMethod={handleChange}
              feedbackInvalid={errors.coupon_value}
              invalid={errors.coupon_value}
            />
          </CRow>
        )}
        <CRow>
          <FormTextInput
            id='coupon_qty'
            name='coupon_qty'
            colSize='6'
            inputLabel='Quantity / Quota'
            placeholder='Enter coupon quantity...'
            className='mb-3'
            value={values.coupon_qty}
            handleTextInput={handleChange}
            feedbackInvalid={errors.coupon_qty}
            invalid={errors.coupon_qty}
          />
        </CRow>
        <CRow>
          <CCol lg='6' className='mb-3'>
            <CFormCheck
              label="Add coupon period"
              onClick={() => setOpenCouponPeriod((prev) => !prev)}
              defaultChecked={openCouponPeriod}
            />
          </CCol>
        </CRow>
        {openCouponPeriod && (
          <CRow>
            <FormDatePickerRange
              id='coupon_period'
              name='coupon_period'
              colSize='12'
              inputLabel='Validity Period'
              placeholder='Enter coupon quantity...'
              className='mb-3'
              startDate={(values.coupon_period_start)}
              endDate={values.coupon_period_end}
              handleStartDateRange={handleStartDateRange}
              handleEndDateRange={handleEndDateRange}
              feedbackInvalid={values.coupon_period_start || values.coupon_period_end}
              invalid={values.coupon_period_start || values.coupon_period_end}
            />
          </CRow>
        )}
        <CRow>
          <CCol lg='6' className='mb-3'>
            <CFormLabel>
              Status
            </CFormLabel>
            <CFormCheck
              id='coupon_is_active'
              name='coupon_is_active'
              label="Active"
              onChange={handleChange}
              checked={values.coupon_is_active}
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  )
}

export default AppFirstForm
