import React, { useState } from 'react'

// components
import {
  CCol,
  CFormLabel,
  CDateRangePicker
} from '@coreui/react-pro'

const FormDatePickerRange = ({
  id,
  name,
  colSize,
  className,
  inputLabel,
  startDate,
  endDate,
  handleStartDateRange,
  handleEndDateRange,
  feedbackInvalid,
  invalid,
}) => {

  return (
    <CCol lg={colSize} className={className}>
      <CFormLabel>
        {inputLabel}
      </CFormLabel>
      <CDateRangePicker
        id={id}
        name={name}
        startDate={(startDate)}
        endDate={endDate}
        onStartDateChange={(value) => handleStartDateRange(value)}
        onEndDateChange={(value) => handleEndDateRange(value)}
        feedbackInvalid={feedbackInvalid}
        invalid={invalid}
      />
    </CCol>
  )
}

export default FormDatePickerRange
