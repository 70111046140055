import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppForm from 'src/components/form/add-coupon/AppForm'
import AppCard from 'src/components/AppCard'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormFirst from 'src/components/form/general-input-form/ActionButtonFormFirst'

// helpers
import Axios from 'src/helpers/axios'

const EditCoupon = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [couponDetails, setCouponDetails] = useState({})
  const [couponTypeOptions, setCouponTypeOptions] = useState([{
    label: "Please select...",
    value: ""
  }, {
    label: "Fixed Amount",
    value: "fixed-amount"
  }, {
    label: "Percentage",
    value: "percentage"
  }])
  const [openCouponPeriod, setOpenCouponPeriod] = useState(false)
  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)

  const formik = useFormik({
    initialValues: {
      coupon_name: '',
      coupon_images: [],
      coupon_desc: '',
      coupon_code: '',
      coupon_type: '',
      coupon_value: '',
      coupon_qty: '',
      coupon_is_active: false,
      coupon_valid_start: '',
      coupon_valid_end: '',
      apply_coupon_to: '',
      uses_per_coupon: '',
      uses_per_customer: '',
      coupon_list_products: []
    },
    validationSchema: Yup.object().shape({
      coupon_name: Yup.string().required("Please enter coupon name."),
      coupon_desc: Yup.string().required("Please enter coupon description."),
      coupon_code: Yup.string().required("Please enter coupon code."),
      coupon_type: Yup.string().required("Please enter coupon type."),
      coupon_value: Yup.string().required("Please enter coupon value."),
      coupon_qty: Yup.string().required("Please enter coupon qty."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      handlePublish(values)
      setSubmitting(false)
    },
  });

  useEffect(() => {
    fetchCouponDetails()
  }, [id]);

  const fetchCouponDetails = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/coupons/products/id/${id}`,
    })
      .then(({ data }) => {
        setCouponDetails(data)
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleInputValue = (values, isDraft) => {
    const formData = new FormData()

    formData.append('name', values.coupon_name)
    formData.append('description', values.coupon_desc)
    formData.append('code', values.coupon_code)
    formData.append('quantity', values.coupon_qty)
    formData.append('type', values.coupon_type)
    formData.append('status', values.coupon_is_active)
    formData.append('is_draft', isDraft)
    if (values.coupon_images.length > 0) {
      formData.append('file', values.coupon_images[0])
    }
    if (values.coupon_valid_start !== '' && values.coupon_valid_end !== '') {
      formData.append('period_start_date', values.coupon_valid_start)
      formData.append('period_end_date', values.coupon_valid_end)
    }
    if (values.uses_per_coupon !== '') {
      formData.append('uses_per_coupon', values.uses_per_coupon)
    }
    if (values.uses_per_customer !== '') {
      formData.append('uses_per_customer', values.uses_per_customer)
    }
    if (values.coupon_list_products.length > 0) {
      formData.append('coupon_list_products', values.coupon_list_products)
    }

    return formData
  }

  const handleEdit = (payload) => {
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/coupons/products/editid/${id}`,
      data: payload
    })
      .then(({ data }) => {
        navigate('/coupons/list')
      })
      .catch((err) => {
        console.error(err);
      })
  }

  const handlePublish = (values) => {
    setIsLoadingPublish(true)
    const inputPayload = handleInputValue(values)
    handleEdit(inputPayload)
    setIsLoadingPublish(false)
  }

  const handleDraft = () => {
    setIsLoadingDraft(true)
    const inputPayload = handleInputValue(formik.values)
    handleEdit(inputPayload)
    setIsLoadingDraft(false)
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      coupon_images: values.coupon_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        coupon_images: attachments
      }), false)
    }
  }

  const handleImageAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
      formik.setValues((values) => ({
        ...values,
        coupon_images: attachments
      }), false)
    }
  }

  const handleCouponIsActive = () => {
    formik.setValues((values) => ({
      ...values,
      coupon_is_active: !values.coupon_is_active
    }), false)
  }

  const handleStartDateRange = (date) => {
    formik.setValues((values) => ({
      ...values,
      coupon_valid_start: date
    }), false)
  }

  const handleEndDateRange = (date) => {
    formik.setValues((values) => ({
      ...values,
      coupon_valid_end: date
    }), false)
  }

  const handleMultiSelect = (value) => {
    formik.setValues((values) => ({
      ...values,
      coupon_list_products: value
    }), false)
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Edit Coupon</h4>}
        bodyContent={
          <AppForm
            editPage
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            couponTypeOptions={couponTypeOptions}
            openCouponPeriod={openCouponPeriod}
            setOpenCouponPeriod={setOpenCouponPeriod}
            handleRemoveImage={handleRemoveImage}
            handleChangeImage={handleImageAttachment}
            handleFileInput={handleImageAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            handleCouponIsActive={handleCouponIsActive}
            handleStartDateRange={handleStartDateRange}
            handleEndDateRange={handleEndDateRange}
            handleMultiSelect={handleMultiSelect}
            couponDetails={couponDetails}
            setOpenFilePreview={setOpenFilePreview}
          />
        }
        footerContent={
          <ActionButtonFormFirst
            // isDraft={couponDetails.isDraft}
            isLoadingDraft={isLoadingDraft}
            isLoadingPublish={isLoadingPublish}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/coupons/list')}
            onDraft={() => handleDraft()}
            onPublish={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default EditCoupon
