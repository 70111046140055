export const productStatus = (status) => {
  switch (status) {
    case 'active':
      return 'success'
    case 'low in stock':
      return 'warning'
    case 'out of stock':
      return 'danger'
    case 'draft':
      return 'secondary'
    default:
      return 'secondary'
  }
}

export const orderStatus = (status) => {
  switch (status) {
    case 'success':
      return 'success'
    case 'processing':
      return 'warning'
    case 'pending':
      return 'secondary'
    default:
      return 'secondary'
  }
}

export const couponTypeText = (status) => {
  switch (status) {
    case 'fixed-amount':
      return 'Fixed Amount'
    case 'percentage':
      return 'Percentage'
    case 'free-shipping':
      return 'Free Shipping'
    case 'buy-one-get-one':
      return 'Buy 1 Get 1'
    default:
      return ''
  }
}