import React from 'react'

// components
import {
  CButton,
} from '@coreui/react-pro'
import AppModal from '../AppModal'

// icons 
import CIcon from '@coreui/icons-react'
import { cilWarning } from '@coreui/icons'

// styling 
import { styleButtonVars3 } from '../custom-styles/buttonStyles'

const AppModalConfirmation = ({
  deleteConfirmation,
  itemName,
  itemDetails,
  showModal,
  setShowModal,
  handleConfirmation
}) => {

  return (
    <>
      {deleteConfirmation && (
        <AppModal
          showModal={showModal}
          setShowModal={setShowModal}
          bodyTitleIcon={<CIcon icon={cilWarning} size='8xl' />}
          bodyTitle={`Are you sure you want to delete this ${itemName}?`}
          bodyDescription={`Once confirmed, it can not be undo and all ${itemName} details will be deleted permanently.`}
          modalFooter={
            <>
              <CButton size='lg' color="secondary" onClick={() => setShowModal({ show: false, item: {} })}>
                No
              </CButton>
              <CButton size='lg' style={styleButtonVars3} onClick={() => handleConfirmation(itemDetails)}>
                Yes
              </CButton>
            </>
          }
        />
      )}
    </>
  )
}

export default AppModalConfirmation