
import React, { useState } from 'react'
import moment from 'moment'

// components
import {
  CRow,
  CBadge,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import { orderStatus } from 'src/helpers/status-badge'
import { getFullName } from 'src/helpers/text-format'

const AppFirstDetails = ({
  purchaseDetails
}) => {

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase Status"
          subtitle={
            <CBadge
              className='table-badge-status-content'
              color={orderStatus(purchaseDetails?.payment_status)}
              shape="rounded-pill"
            >
              {purchaseDetails?.payment_status?.toString().toUpperCase()}
            </CBadge>
            // <CDropdown variant="btn-group">
            //   <CDropdownToggle color={orderStatus(purchaseStatus)}>
            //     {purchaseStatus.toString().toUpperCase()}
            //     {purchaseStatus.toString().toUpperCase()}
            //   </CDropdownToggle>
            //   <CDropdownMenu>
            //     <CDropdownItem onClick={() => setPurchaseStatus('pending')}>Pending</CDropdownItem>
            //     <CDropdownItem onClick={() => setPurchaseStatus('processing')}>Processing</CDropdownItem>
            //     <CDropdownItem onClick={() => setPurchaseStatus('success')}>Success</CDropdownItem>
            //   </CDropdownMenu>
            // </CDropdown>
          }
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase ID"
          subtitle={purchaseDetails?.transaction_id}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase Date / Time"
          subtitle={moment(purchaseDetails?.transaction_date).format('DD/MM/YYYY HH:mm')}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Discount / Promo Used"
          subtitle={purchaseDetails?.promotions || "-"}
        />
      </CRow>
      <CRow>
        {/* <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Email ID"
          subtitle={"ID153431"}
          subtitle={purchaseDetails?.guest_token ? 'GUEST': 'MEMBER'}
        /> */}
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Status"
          subtitle={purchaseDetails?.guest_token ? 'GUEST' : 'MEMBER'}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Name"
          subtitle={getFullName(purchaseDetails?.user?.profile)}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Amount Purchase"
          subtitle={`IDR ${purchaseDetails?.transaction_amount}`}
        />
      </CRow>
    </>
  )
}

export default AppFirstDetails