import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";

// components
import {
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane
} from '@coreui/react-pro';
import CustomerPurchaseHistory from './CustomerPurchaseHistory';
import CustomerWishlist from './CustomerWishlist'

const AppSecondDetails = ({
  customerDetails,
  customerPurchaseHistory
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [activeTabKey, setActiveTabKey] = useState(null);

  const tabs = [{
    id: 1,
    label: "Purchase History",
  }];

  useEffect(() => {
    const tabKey = parseInt(searchParams.get("tab"));
    if (!tabKey) {
      setActiveTabKey(1);
    }
  }, []);

  const handleClicktab = (id) => {
    setActiveTabKey(id);
    navigate(`/customers/details/list?tab=${id}`);
  };

  return (
    <>
      <CNav variant='underline'>
        {tabs.map((tab, index) => (
          <CNavItem key={index}>
            <CNavLink
              type="button"
              active={activeTabKey === tab.id}
              onClick={() => handleClicktab(tab.id)}
            >
              {tab.label}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent>
        <CTabPane visible={activeTabKey === 1}>
          <CustomerPurchaseHistory
            customerDetails={customerDetails}
            customerPurchaseHistory={customerPurchaseHistory}
          />
        </CTabPane>
      </CTabContent>
    </>
  )
}

export default AppSecondDetails
