import React, { Component, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  CSpinner,
  CToaster,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/react-pro";
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
import DefaultLayout from './layout/DefaultLayout'

// Pages
import Login from './views/pages/login/Login'
import Register from './views/pages/register/Register'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'

const App = () => {
  const dispatch = useDispatch();

  const toastMessage = useSelector((state) => state.toastMessage);

  return (
    <BrowserRouter>
      {/* <CToaster placement="top-end">
        <CToast
          autohide={true}
          visible={toastMessage.isShown}
          color={toastMessage.color}
          className="text-white align-items-center"
          onClose={() => {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: false,
                isShown: false,
                color: "primary",
                text: "",
              },
            });
          }}
        >
          <div className="d-flex">
            <CToastBody>{toastMessage.text}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>
      </CToaster> */}
      <Suspense fallback={loading}>
        <Routes>
          <Route path="*" name="Home" element={<DefaultLayout />} />
          <Route path="/login" name="Login Page" element={<Login />} />
          <Route path="/register" name="Register Page" element={<Register />} />
          <Route path="/404" name="Page 404" element={<Page404 />} />
          <Route path="/500" name="Page 500" element={<Page500 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
