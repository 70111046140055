
import React from 'react'
import moment from 'moment'

// components
import {
  CCol,
  CRow,
  CFormCheck,
  CFormLabel
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'
import AppImageDetail from '../general-details/AppImageDetails'

const AppFirstDetails = ({
  couponDetails,
  couponImages
}) => {
  return (
    <CRow>
      <CCol lg='6'>
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Coupon Name"
          subtitle={'Summer Sale Promo'}
        // subtitle={couponDetails?.name}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3 text-justify"
          title="Coupon Description"
          subtitle={'Lorem Ipsum'}
        // subtitle={couponDetails?.description}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Coupon Code"
          subtitle={'SUMMER50'}
        // subtitle={couponDetails?.code}
        />
        <AppImageDetail
          colSize="12"
          className="mb-3"
          title="Banner Image"
        // productImages={couponImages[0]}
        />
      </CCol>
      <CCol lg='6'>
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Coupon Type"
          subtitle={'Percentage, 50%'}
        // subtitle={couponDetails?.type}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Quantity"
          subtitle={'100'}
        // subtitle={couponDetails?.quantity}
        />
        <AppTextColumnDetails
          colSize="12"
          className="mb-3"
          title="Validity Period"
          subtitle={'01/04/2023 - 01/05/2023'}
        // subtitle={couponDetails?.validity_period}
        />
        <CCol lg='6' className='mb-3'>
          <CFormLabel>
            Status
          </CFormLabel>
          <CFormCheck
            label="Active"
            onClick={() => console.log("Coupon Active")}
            defaultChecked
          // defaultChecked={couponDetails?.is_active}
          />
        </CCol>
      </CCol>
    </CRow>
  )
}

export default AppFirstDetails