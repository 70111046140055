import React from 'react'
import {
  CButton,
} from '@coreui/react-pro'
import { styleButtonVars3 } from '../custom-styles/buttonStyles'


const AppCardHeader = ({
  headerTitle,
  headerButton,
  headerDownloadButton,
  handleCardHeaderButton,
  downloadLink,
  downloadName,
  disabled
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      {headerTitle}
      {headerButton && (
        <CButton
          style={styleButtonVars3}
          onClick={() => handleCardHeaderButton()}
          disabled={disabled}
        >
          {headerButton}
        </CButton>
      )}
      {headerDownloadButton && (
        <CButton
          style={styleButtonVars3}
          href={downloadLink}
          download={downloadName}
        >
          {headerDownloadButton}
        </CButton>
      )}
    </div>
  )
}

export default AppCardHeader
