import React, { useState, useEffect } from 'react'

// components
import AppCard from 'src/components/AppCard'
import AppTable from 'src/components/AppTable'
import AppModalConfirmation from 'src/components/modal/AppModalConfirmation'

// helpers
import { columnPurchaseList } from 'src/components/table-column/TableColumnList'
import { formUrlQuery } from 'src/helpers/format-queries'
import Axios from 'src/helpers/axios'
import { getFullName } from 'src/helpers/text-format'

const PurchaseList = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState({ show: false, item: {} })

  const [purchaseList, setPurchaseList] = useState([]);
  const [purchaseListCount, setPurchaseListCount] = useState(0);
  const [purchaseListPerPage, setPurchaseListPerPage] = useState(10);
  const [activePurchaseListPage, setActivePurchaseListPage] = useState(1);
  const [searchPurchaseListFilter, setSearchPurchaseListFilter] = useState("");
  const [columnPurchaseListSorter, setColumnPurchaseListSorter] = useState({ column: "transaction_date", state: "desc" });

  useEffect(() => {
    // let queries = {
    //   search: searchPurchaseListFilter,
    //   // limit: purchaseListPerPage,
    //   offset: purchaseListPerPage * activePurchaseListPage - purchaseListPerPage,
    //   sort:
    //     (columnPurchaseListSorter &&
    //       `${columnPurchaseListSorter.column}%${columnPurchaseListSorter.state}`) ||
    //     "",
    // };
    // queries = formUrlQuery(queries);
    const payload = {
      limit: null,
      offset: purchaseListPerPage * activePurchaseListPage - purchaseListPerPage,
      order: ['createdAt', 'DESC']
    }
    fetchAllPurchases(payload);
  }, [
    searchPurchaseListFilter,
    purchaseListPerPage,
    activePurchaseListPage,
    columnPurchaseListSorter,
  ]);

  const fetchAllPurchases = (payload) => {
    Axios({
      method: "post",
      url: `/api/ecommerce/v1/transactions/all`,
      payload
    })
      .then(({ data }) => {
        const tempPurchaseList = data.map((d) => ({
          ...d,
          // customer_name: getFullName(d?.user?.profile),
          customer_name: d?.customer_email?.email || 'Not Available',
          payment_type: d?.payment_channel?.name || '-'
        }));

        setPurchaseList(tempPurchaseList);
        if (activePurchaseListPage === 1) {
          setPurchaseListCount(tempPurchaseList?.length);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDeleteConfirmation = (item) => {
    Axios({
      method: "delete",
      url: `/api/ecommerce/v1/transactions/delete/id/${item.id}`,
    })
      .then(({ data }) => {
        setShowConfirmDelete({ show: false, item: {} })
        window.location.reload()
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <>
      {showConfirmDelete.show && (
        <AppModalConfirmation
          deleteConfirmation
          itemName='purchase'
          itemDetails={showConfirmDelete.item}
          showModal={showConfirmDelete}
          setShowModal={setShowConfirmDelete}
          handleConfirmation={handleDeleteConfirmation}
        />
      )}
      <AppCard
        className='mt-4 mb-4'
        headerTitle={<h4>Purchase List</h4>}
        bodyContent={
          <AppTable
            purchaseListPage
            selectable
            setShowConfirmDelete={setShowConfirmDelete}
            tableColumns={columnPurchaseList}
            tableContent={purchaseList}
            contentPerPage={purchaseListPerPage}
            activeContentPage={activePurchaseListPage}
            contentCount={purchaseListCount}
            searchContentFilter={searchPurchaseListFilter}
            setActiveContentPage={setActivePurchaseListPage}
            setSearchContentFilter={setSearchPurchaseListFilter}
            setContentPerPage={setPurchaseListPerPage}
            columnContentSorter={columnPurchaseListSorter}
            setColumnContentSorter={setColumnPurchaseListSorter}
          />
        }
      />
    </>
  )
}

export default PurchaseList
