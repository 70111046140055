import React, { useState } from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormCheck
} from '@coreui/react-pro'
import FormTextInput from '../general-input-form/FormTextInput'
import FormTextAreaInput from '../general-input-form/FormTextAreaInput'
import FormProductPrice from '../general-input-form/FormProductPrice'
import FormImageUpload from '../general-input-form/FormImageUpload'
import FormImageThumbnail from '../general-input-form/FormImageThumbnail'
import FormSelectInput from '../general-input-form/FormSelectInput'

const AppFirstForm = ({
  editPage,
  categoryOptions,
  subCategoryOptions,
  subCategoryDetails,
  openFormProductVariant,
  setOpenFormProductVariant,
  openFormProductCombination,
  handleVoucherType,
  setOpenFilePreview,
  values,
  errors,
  handleChange,
  handleChangeImage,
  handleFileInput,
  handleFileOnDrop,
  handleRemoveImage
}) => {

  return (
    <>
      <CRow className="mb-4">
        <FormTextInput
          id='product_name'
          name='product_name'
          colSize='6'
          inputLabel='Voucher Name'
          placeholder='Enter voucher name...'
          value={values.product_name}
          handleTextInput={handleChange}
          feedbackInvalid={errors.product_name}
          invalid={errors.product_name}
        />
        <FormSelectInput
          id='category_id'
          name='category_id'
          colSize='3'
          inputLabel='Game Type'
          selectOptions={categoryOptions}
          value={values.category_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.category_id}
          invalid={errors.category_id}
        />
        <FormSelectInput
          id='subcategory_id'
          name='subcategory_id'
          colSize='3'
          inputLabel='Game List'
          selectOptions={subCategoryOptions}
          value={values.subcategory_id}
          handleSelectInput={handleChange}
          feedbackInvalid={errors.subcategory_id}
          invalid={errors.subcategory_id}
        />
      </CRow>
      <CRow className="mb-4">
        <CCol lg='6'>
          <CFormLabel>
            Voucher Type:
          </CFormLabel>
          <CFormCheck
            name="redeemable-voucher"
            type="radio"
            className='mt-2'
            label="Redeemable Voucher"
            disabled={subCategoryDetails && subCategoryDetails?.is_subcategory_voucher === true}
            checked={values.is_product_voucher === false}
            onChange={(e) => handleVoucherType(e.target.name)}
          />
          <CFormCheck
            name="product-voucher"
            type="radio"
            className='mt-2'
            label="Product Voucher"
            disabled={subCategoryDetails && subCategoryDetails?.is_subcategory_voucher === true}
            checked={values.is_product_voucher === true}
            onChange={(e) => handleVoucherType(e.target.name)}
          />
        </CCol>
        {openFormProductCombination ? (
          null
        ) : (
          <FormTextInput
            id='product_quantity'
            name='product_quantity'
            colSize='3'
            inputLabel='Quantity / Stock'
            placeholder='Input quantity / stock...'
            value={values.product_quantity}
            handleTextInput={handleChange}
            feedbackInvalid={errors.product_quantity}
            invalid={errors.product_quantity}
          />
        )}
        {openFormProductCombination ? (
          null
        ) : (
          <>
            <FormProductPrice
              id='product_price'
              name='product_price'
              colSize='3'
              inputLabel='Voucher Price'
              placeholder='Enter voucher price...'
              priceCurrency='IDR'
              value={values.product_price}
              handlePriceInput={handleChange}
              feedbackInvalid={errors.product_price}
              invalid={errors.product_price}
            />
            {/* <FormProductPrice
              id='product_sale_price'
              name='product_sale_price'
              colSize='3'
              inputLabel='voucher Sale Price'
              placeholder='Enter voucher sale price...'
              priceCurrency='IDR'
              value={values.product_sale_price}
              handlePriceInput={handleChange}
              feedbackInvalid={errors.product_sale_price}
              invalid={errors.product_sale_price}
            /> */}
          </>
        )}
      </CRow>
      <CRow className="mb-4">
        <FormTextAreaInput
          id='product_desc'
          name='product_desc'
          colSize='6'
          inputLabel='Voucher Description'
          textDescription='Maximum description text is 300 characters'
          placeholder='Enter voucher description...'
          rows='10'
          value={values.product_desc}
          handleTextAreaInput={handleChange}
          feedbackInvalid={errors.product_desc}
          invalid={errors.product_desc}
        />
        {values.product_images.length > 0 ? (
          <FormImageThumbnail
            editPage={editPage}
            name='product_images'
            colSize='6'
            inputLabel='Voucher Banner Image'
            productImages={values.product_images}
            handleRemoveImage={handleRemoveImage}
            handleChangeImage={handleChangeImage}
            setOpenFilePreview={setOpenFilePreview}
          />
        ) : (
          <FormImageUpload
            pageAddProduct
            name='product_images'
            colSize='6'
            inputLabel='Voucher Banner Image'
            handleFileInput={handleFileInput}
            handleFileOnDrop={handleFileOnDrop}
            multiple={false}
            feedbackInvalid={errors.product_images}
            invalid={errors.product_images}
          />
        )}
      </CRow>
      {/* <CRow className="mb-4">
        <CCol lg='6'>
          <CFormCheck
            className='me-2'
            label="Add product variant"
            onClick={() => setOpenFormProductVariant((prev) => !prev)}
            defaultChecked={openFormProductVariant}
          />
        </CCol>
        <CCol lg='6'>
          {null}
        </CCol>
      </CRow> */}
    </>
  )
}

export default AppFirstForm
